import { Grid } from '@material-ui/core';
import SideNavigation from '../navigation/SideNavigation';
import CustomBottomNavigation from '../navigation/BottomNavigation';
import OldOrderPanel from './panels/OldOrderPanel';

const OldOrderPageer = () => (
	<>
		<Grid container justifyContent='center'>
			<Grid item xs={4} md={2}>
				<SideNavigation pathname='/processing-order' />
			</Grid>
			<Grid item xs={8} md={4}>
				<OldOrderPanel />
			</Grid>
		</Grid>
		<CustomBottomNavigation value='1' />
	</>
);
export default OldOrderPageer;
