import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import { ArrowBack, LocalOffer } from '@material-ui/icons';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Button, Grid } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useStyles } from 'theme/orderPage';
import { useTranslation } from 'react-i18next';
import { useViewOrder } from 'utils/orders';
import automateLogo from 'images/logo_automate_alt_yellow.png';
import getServiceDetail from './panelActions/getServiceDetail';
import getOrderDetail from './panelActions/getOrderDetail';

const carLogoPath =
	'https://images.automate-app.com/app/automate_pro/car_emblem_mechanic_invert/';

const OldOrderPanel = () => {
	const { orderId } = useParams<{ shopId: string; orderId: string }>();
	const classes = useStyles();
	const history = useHistory();
	const viewOrder = useViewOrder(orderId);
	const services = (viewOrder && getServiceDetail(viewOrder)) || [];
	const order = (viewOrder && getOrderDetail(viewOrder)) || [];
	const { t } = useTranslation(['Order', 'Moment']);
	moment.locale(t('Moment:lang'));

	const getOrderType = () => {
		if (order.orderType === 'prepaid_fixed_price')
			return (
				<TableRow key='OrderType'>
					<TableCell className={classes.orderType} colSpan={3}>
						<div>
							<img className={classes.img} src={automateLogo} alt='Logo' />
							{t('Fixed Price Service')}
						</div>
					</TableCell>
				</TableRow>
			);

		if (order.orderType === 'offer')
			return (
				<TableRow key='offer'>
					<TableCell
						className={classes.orderType}
						colSpan={3}
						component='th'
						scope='row'>
						<div>
							<LocalOffer />
							{t('Offer')}
						</div>
					</TableCell>
				</TableRow>
			);
		return null;
	};

	const getServices = services.map((service: any) => (
		<TableRow key={service.name}>
			<TableCell colSpan={3} className={classes.tableCell}>
				<div style={{ float: 'right' }}>
					<span className={classes.price}> ${service.price}</span>
				</div>
				<div className={classes.serviceName}>{service.name}</div>
				<div className={classes.serviceDetails}>{service.serviceDetails}</div>
			</TableCell>
		</TableRow>
	));

	const getQuotationTotalPrice = (percent: number) => {
		if (order.totalPrice === 0) return null;
		return (
			<>
				<TableRow>
					<TableCell className={classes.table}>
						<div className={classes.priceLabel}>{t('Total')} </div>
					</TableCell>
					<TableCell className={classes.totalPrice} align='right'>
						${Number(order.totalPrice).toFixed(2)}
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell className={classes.table}>
						<div className={classes.priceLabel}>
							{t('Fee')}({percent}%)
						</div>
					</TableCell>
					<TableCell className={classes.totalPrice} align='right'>
						-${((order.totalPrice * percent) / 100).toFixed(2)}
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell className={classes.yellow}>
						<div className={classes.priceLabel}>{t('Net Income')}</div>
					</TableCell>
					<TableCell
						className={`${classes.totalPrice} ${classes.yellow}`}
						align='right'>
						${(order.totalPrice * (1 - percent / 100)).toFixed(2)}
					</TableCell>
				</TableRow>
			</>
		);
	};

	const getTotalPrice = () => {
		if (order.totalPrice === 0) return null;
		return (
			<TableRow key='totalPrice'>
				<TableCell
					colSpan={2}
					className={classes.table}
					component='th'
					scope='row'>
					<div className={classes.priceLabel}>{t('Total')}</div>
				</TableCell>
				<TableCell className={classes.totalPrice} align='right'>
					${order.totalPrice}
				</TableCell>
			</TableRow>
		);
	};

	return (
		<Grid container justifyContent='center'>
			<Grid item xs={12}>
				<IconButton onClick={() => history.goBack()} className={classes.yellow}>
					<ArrowBack />
				</IconButton>
				<span className={classes.yellow}>{t('Order Detail')} </span>
				<br />
				<div className={classes.car}>
					<img
						className={classes.img}
						src={carLogoPath + order.carLogo}
						alt='Car Logo'
					/>
					<div className={classes.carModel}>{order.car}</div>
					<div className={classes.carLicensePlate}>{order.licensePlate}</div>
					<div className={classes.carOwner}>{order.name}</div>
				</div>

				<div className={classes.bookedDate}>
					<div className={classes.preferDate}>
						{t('Completed on')}
						{moment(order.preferDate).format('MMM Do')}
					</div>
				</div>
				<Table className={classes.table} aria-label='Old Order'>
					<TableBody>
						{getOrderType()}
						{getServices}
						{order.orderType === 'prepaid_fixed_price' && getTotalPrice()}
						{order.orderType === 'offer' && getQuotationTotalPrice(7.5)}
						{order.orderType === 'quotation' && getQuotationTotalPrice(5)}
					</TableBody>
				</Table>
				<div className={classes.skipButton}>
					<Button
						className={classes.button2}
						color='secondary'
						variant='outlined'
						size='large'
						fullWidth>
						{t('Rate')}
					</Button>
				</div>
			</Grid>
		</Grid>
	);
};

export default OldOrderPanel;
