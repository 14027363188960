import { ChevronRight } from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import { Rating } from '@material-ui/lab';
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { useViewShop } from 'utils/orders';
import { useStyles } from 'theme/components';
import defaultLogo from 'images/placeholder_user_yellow.png';
import { useTranslation } from 'react-i18next';

const logoPath = 'https://images.automate-app.com';
interface MenuTabsProps {
	shopId: string;
}

const MenuTabs = ({ shopId }: MenuTabsProps) => {
	const classes = useStyles();
	const name = localStorage.getItem('username') ?? 'Profile';
	const { shopName, rate } = useViewShop(shopId);
	const { t } = useTranslation('Menu');

	const logo = localStorage.userLogo
		? `${logoPath}${localStorage.userLogo}`
		: defaultLogo;

	return (
		<List>
			<ListItem
				className={classes.listItem}
				component={Link}
				to='/profile'
				button
				key='shop-profile'>
				<ListItemText>
					<img className={classes.shopLogo} src={logo} alt='Logo' />
					<div className={classes.userName}>{name}</div>
				</ListItemText>
				<ListItemIcon className={classes.icon}>
					<ChevronRight />
				</ListItemIcon>
			</ListItem>
			<Divider className={classes.shopDivider} />
			<ListItem key='shopName'>
				<ListItemText>
					<div className={classes.shopName}>{shopName}</div>
				</ListItemText>
			</ListItem>
			<ListItem key='ratings'>
				<ListItemText>
					<Rating name='read-only' precision={0.5} readOnly value={rate} />
					<span className={classes.rate}>{rate}</span>
				</ListItemText>
			</ListItem>
			<ListItem
				className={classes.listItem}
				component={Link}
				to='/order-history'
				button
				key='order-record'>
				<ListItemText>{t('Order History')}</ListItemText>
				<ListItemIcon className={classes.icon}>
					<ChevronRight />
				</ListItemIcon>
			</ListItem>
			<ListItem
				key='language'
				component={Link}
				to='/language'
				button
				className={classes.listItem}>
				<ListItemText>Language</ListItemText>
				<ListItemIcon className={classes.icon}>
					<ChevronRight />
				</ListItemIcon>
			</ListItem>
			<Divider className={classes.divider} />
			<ListItem
				className={classes.listItem}
				component={Link}
				key='q&a'
				to={{
					pathname: '/policy',
					state: {
						policy: t('FAQ'),
						url: 'https://proapp.automate-app.com/hk/zh/faq',
					},
				}}>
				<ListItemText>{t('FAQ')}</ListItemText>
				<ListItemIcon className={classes.icon}>
					<ChevronRight />
				</ListItemIcon>
			</ListItem>
			<ListItem
				className={classes.listItem}
				component={Link}
				to={{
					pathname: '/policy',
					state: {
						policy: t('Agreement'),
						url: 'https://proapp.automate-app.com/hk/Agreement_autoshop',
					},
				}}
				key='mechanics'>
				<ListItemText>{t('Agreement')}</ListItemText>
				<ListItemIcon className={classes.icon}>
					<ChevronRight />
				</ListItemIcon>
			</ListItem>
			<ListItem
				className={classes.listItem}
				component={Link}
				to={{
					pathname: '/policy',
					state: {
						policy: t('Privacy Policy'),
						url: 'https://proapp.automate-app.com/hk/privacy_policy',
					},
				}}
				key='privacy-policy'>
				<ListItemText>{t('Privacy Policy')}</ListItemText>
				<ListItemIcon className={classes.icon}>
					<ChevronRight />
				</ListItemIcon>
			</ListItem>
			<ListItem
				className={classes.listItem}
				component={Link}
				to={{
					pathname: '/policy',
					state: {
						policy: t('Terms and Conditions'),
						url: 'https://proapp.automate-app.com/hk/terms',
					},
				}}
				key='policy'>
				<ListItemText>{t('Terms and Conditions')}</ListItemText>
				<ListItemIcon className={classes.icon}>
					<ChevronRight />
				</ListItemIcon>
			</ListItem>
		</List>
	);
};

export default MenuTabs;
