import { Grid } from '@material-ui/core';
import ShopListTabs from './tabs/ShopListTabs';
import BottomNav from '../navigation/BottomNavigation';

const ProcessingOrderPage = () => (
	<>
		<Grid container justifyContent='center'>
			<Grid item xs={12} md={6}>
				<ShopListTabs />
			</Grid>
		</Grid>
		<BottomNav value='1' />
	</>
);

export default ProcessingOrderPage;
