import { Box, Button } from '@material-ui/core';
import { Android } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import homePageLogo from 'images/logo_automate_yellow.png';

const HeaderPreLogin = () => (
	<Box display='flex' width='100%' justifyContent='space-between'>
		<Button component={Link} to='/'>
			<img src={homePageLogo} alt='home_page_logo' width='200px' />
		</Button>

		<Button
			color='secondary'
			href='https://play.google.com/store/apps/details?id=com.automate.automatepro&hl=zh_HK'
			target='_blank'
			startIcon={<Android />}>
			Download
		</Button>
	</Box>
);

export default HeaderPreLogin;
