import { useRef, useState } from 'react';
import PinInput from 'react-pin-input';
import { ArrowBack } from '@material-ui/icons';
import {
	Box,
	Container,
	Grid,
	IconButton,
	Paper,
	Typography,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { confirmPickUpPin } from 'utils/orders';
import { useTranslation } from 'react-i18next';
import CustomDialog from '../CustomDialog';

function PickUpPinPage() {
	const { t } = useTranslation(['PickUpPin']);
	const [Open, setOpen] = useState(false);
	const pinInput = useRef<PinInput>(null);
	const history = useHistory();
	const { shopId, orderId } = useParams<{ shopId: string; orderId: string }>();

	const handleClose = () => setOpen(false);
	const handleOpen = () => setOpen(true);
	const next = () => {
		const path = '/job-done';
		const state = {
			jobTitle: '車主已取車',
			jobDetails: '成功確認車主已經取車，做得好，付款會立即安排，多謝。',
			nextPath: '/processing-order',
		};
		history.replace(path, state);
	};

	const completePickUpPin = (pin: string) => {
		confirmPickUpPin(orderId, shopId, pin)
			.then((data: any) => {
				if (!data.error) {
					return next();
				}
				if (pinInput.current) pinInput.current.clear();
				handleOpen();
				return null;
			})
			.catch(() => {
				if (pinInput.current) pinInput.current.clear();
				handleOpen();
				return null;
			});
	};

	return (
		<>
			{Open && (
				<CustomDialog
					dispatch={handleClose}
					title='Error'
					text='Wrong code'
					button='OK'
				/>
			)}
			<Container maxWidth='sm'>
				<Box display='flex' alignItems='center'>
					<IconButton onClick={history.goBack}>
						<ArrowBack color='primary' />
					</IconButton>
					<Typography color='secondary'>{t('Confirm job done')}</Typography>
				</Box>
				<Grid container justifyContent='center' style={{ marginTop: '20vh' }}>
					<Grid item>
						<Paper>
							<Box
								display='flex'
								alignItems='center'
								flexDirection='column'
								padding={5}>
								<PinInput
									focus
									length={4}
									ref={pinInput}
									type='numeric'
									onComplete={completePickUpPin}
								/>
								<Typography style={{ marginTop: '2em', textAlign: 'center' }}>
									{t('message')}
								</Typography>
							</Box>
						</Paper>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}

export default PickUpPinPage;
