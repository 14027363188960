/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles, Tab, Table, TableBody } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { useOrderHistory } from 'utils/orders';
import EmptyList from '../../EmptyList';
import { ordersRow } from './NewOrderTabs';

const useStyles = makeStyles({
	img: {
		width: '20px',
		marginLeft: '3px',
		marginRight: '12px',
		verticalAlign: 'middle',
		objectFit: 'contain',
	},
	root: {
		flexGrow: 1,
	},
	card: {
		padding: '1%',
	},
	table: {
		color: 'white',
		fontSize: 16,
		fontWeight: 300,
		borderBottomColor: 'rgba(255, 255, 255, 0.15)',
		borderBottomWidth: '1px',
		borderBottomStyle: 'solid',
	},
	dateCell: {
		color: 'rgba(255, 255, 255, 0.65)',
		backgroundColor: 'black',
		padding: '6px',
		fontSize: '16px',
		fontWeight: 300,
	},
	noPadding: {
		padding: '0',
	},
});

interface OrderHistoryTabsProps {
	shopId: string;
}

const OrderHistoryTabs = (props: OrderHistoryTabsProps) => {
	const { shopId } = props;
	const classes = useStyles();
	const [value, setValue] = useState('one');
	const handleChange = (_event: any, newValue: string) => setValue(newValue);
	const { t } = useTranslation('Tabs');

	const { fixedPriceList, quotationList, offerList } = useOrderHistory(shopId);

	const getFixedPriceList = () =>
		ordersRow(fixedPriceList, shopId, classes, '/old-order');
	const renderFixedPrice = fixedPriceList.length ? (
		<Table aria-label='prepaid-fixed-price'>
			<TableBody>{getFixedPriceList()}</TableBody>
		</Table>
	) : (
		<EmptyList />
	);

	const getQuotationList = () =>
		ordersRow(quotationList, shopId, classes, '/old-order');
	const renderQuotation = quotationList.length ? (
		<Table aria-label='quotation'>
			<TableBody>{getQuotationList()}</TableBody>
		</Table>
	) : (
		<EmptyList />
	);

	const getOfferList = () =>
		ordersRow(offerList, shopId, classes, '/old-order');
	const renderOffer = offerList.length ? (
		<Table aria-label='offer'>
			<TableBody>{getOfferList()}</TableBody>
		</Table>
	) : (
		<EmptyList />
	);

	return (
		<Grid container>
			<Grid item xs>
				<TabContext value={value}>
					<TabList
						onChange={handleChange}
						indicatorColor='primary'
						textColor='primary'
						variant='fullWidth'>
						<Tab label={t('tabFixed')} value='one' />
						<Tab label={t('tabQuotation')} value='two' />
						<Tab label={t('tabOffer')} value='three' />
					</TabList>
					<TabPanel className={classes.noPadding} value='one'>
						{renderFixedPrice}
					</TabPanel>
					<TabPanel className={classes.noPadding} value='two'>
						{renderQuotation}
					</TabPanel>
					<TabPanel className={classes.noPadding} value='three'>
						{renderOffer}
					</TabPanel>
				</TabContext>
			</Grid>
		</Grid>
	);
};

export default OrderHistoryTabs;
