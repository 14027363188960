import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import emptyListIcon from 'images/emptystate_job_list.png';

interface EmptyListProps {
	text?: string;
}

const useStyles = makeStyles({
	container: {
		height: '80vh',
		width: '100%',
	},
});

const EmptyList = ({ text = 'EMPTY' }: EmptyListProps) => {
	const classes = useStyles();

	return (
		<Box
			className={classes.container}
			display='flex'
			flexDirection='column'
			alignItems='center'
			justifyContent='center'>
			<img
				src={emptyListIcon}
				alt='Logo for empty job list'
				width='80px'
				height='80px'
			/>
			<h6>{text}</h6>
		</Box>
	);
};

export default EmptyList;
