import { Grid } from '@material-ui/core';
import SideNavigation from '../navigation/SideNavigation';
import CustomBottomNavigation from '../navigation/BottomNavigation';
import OrderPanel from './panels/OrderPanel';

const OrderPage = () => (
	<>
		<Grid container justifyContent='center'>
			<Grid item xs={4} md={2}>
				<SideNavigation pathname='/new-order' />
			</Grid>
			<Grid item xs={8} md={4}>
				<OrderPanel />
			</Grid>
		</Grid>
		<CustomBottomNavigation value='0' />
	</>
);

export default OrderPage;
