import { createTheme } from '@material-ui/core/styles';
import Color from './colors';

const theme = createTheme({
	typography: {
		fontFamily: '"New Transport","Ping Fang HK", sans-serif!important',
	},
	palette: {
		primary: {
			main: Color.yellow,
		},
		secondary: {
			main: Color.white,
			contrastText: '#000000',
		},
		background: {
			default: '#222222',
		},
	},
	overrides: {
		MuiTabs: {
			indicator: {
				backgroundColor: Color.yellow,
			},
		},
		MuiTab: {
			textColorPrimary: {
				color: 'rgba(255, 255, 255, 0.82)',
			},
			root: {
				fontSize: 16,
				fontWeight: 700,
			},
			wrapper: {
				flexDirection: 'row',
				'&$wrapper > *:first-child': {
					margin: '0',
				},
			},
		},
		MuiTableRow: {
			hover: {
				'&$hover:hover': {
					backgroundColor: 'rgb(78, 78, 78)',
				},
			},
		},
		MuiTableCell: {
			body: {
				color: 'white',
			},
			root: {
				borderBottom: 'none',
			},
		},
		MuiCheckbox: {
			root: {
				color: 'white',
			},
		},
		MuiIconButton: {
			root: {
				'&:hover': {
					color: 'white',
				},
			},
		},
		MuiFormLabel: {
			root: {
				color: 'rgb(170,170,170)',
			},
		},
		MuiInput: {
			underline: {
				'&:before': {
					borderBottom: '1px solid rgb(170,170,170)',
				},
				'&:hover:not($disabled):before': {
					borderBottom: '1px solid rgb(170,170,170)',
				},
			},
		},
	},
});

export default theme;
