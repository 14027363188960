import { Grid } from '@material-ui/core';
import SideNavigation from '../navigation/SideNavigation';
import CustomBottomNavigation from '../navigation/BottomNavigation';
import ScheduledOrderPanel from './panels/ScheduledOrderPanel';

const ScheduledOrderPage = () => (
	<>
		<Grid container justifyContent='center'>
			<Grid item xs={4} md={2}>
				<SideNavigation pathname='/processing-order' />
			</Grid>
			<Grid item xs={8} md={4}>
				<ScheduledOrderPanel />
			</Grid>
		</Grid>
		<CustomBottomNavigation value='1' />
	</>
);

export default ScheduledOrderPage;
