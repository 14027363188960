import { makeStyles } from '@material-ui/styles';

// Todo: migrate dependencies to useStyles
const componentsStyle = {
	button1: {
		'& .MuiButton-endIcon': {
			position: 'absolute',
			right: 16,
		},
		fontSize: '18px',
		fontWeight: '700',
		maxWidth: '90%',
		borderRadius: '25px',
	},
	acceptButton: {
		marginTop: '50px',
		textAlign: 'center',
	},
	changePassword: {
		color: '#FFE700',
		fontSize: '25px',
	},
	form: {
		color: 'white',
	},
	formContainer: {
		padding: '20px',
	},
	rate: {
		display: 'inline-block',
		paddingLeft: '2px',
		verticalAlign: 'text-bottom',
		fontSize: '16px',
		color: '#FFE700',
	},
	shopName: {
		fontSize: '18px',
		fontWeight: '700',
	},
	profileName: {
		color: '#FFE700',
		margin: '30px',
		fontSize: '21px',
		fontWeight: '700',
	},
	profileDetails: {
		color: '#FFE500',
		fontWeight: '300',
		fontSize: '16px',
	},
	shopLogo: {
		borderRadius: '50%',
		width: '50px',
		height: '50px',
		verticalAlign: 'middle',
		display: 'inline-block',
		marginRight: '5%',
	},
	profileShopLogo: {
		borderRadius: '50%',
		width: '150px',
		height: '150px',
		verticalAlign: 'middle',
		display: 'inline-block',
		margin: 'auto',
	},
	profileContainer: {
		textAlign: 'center',
	},
	userName: {
		verticalAlign: 'middle',
		display: 'inline-block',
		color: '#FFE700',
		fontSize: '18px',
		fontWeight: '700',
	},
	list: {
		color: 'white',
	},
	listItem: {
		color: 'white',
		'&:hover': {
			color: '#FFE700',
		},
	},
	listItemLogout: {
		color: '#D0021B',
		fontWeight: '500',
		fontSize: '16px',
		'&:hover': {
			color: 'grey',
		},
	},
	icon: {
		color: 'rgb(255,255,255,0.5)',
	},
	divider: {
		backgroundColor: 'white',
	},
	shopDivider: {
		backgroundColor: '#FFE700',
	},
	yellow: {
		color: '#FFE700',
	},
	grey: {
		color: 'rgb(255,255,255,0.5)',
	},
	backdrop: {
		zIndex: 10,
		color: '#FFFFFF',
	},
	logo: {
		backgroundColor: '#222222',
		textAlign: 'center',
		padding: '9px',
		verticalAlign: 'middle',
	},
	nav: {
		backgroundColor: '#000000',
	},
	navButton: {
		color: 'rgba(255,255,255,0.90)',
	},
	container: {
		paddingTop: '0px',
	},
	brand: {
		color: '#FFFFFF',
		textAlign: 'left',
	},
	title: {
		fontSize: '4.2rem',
		fontWeight: '600',
		display: 'inline-block',
		position: 'relative',
	},
	subtitle: {
		fontSize: '1.313rem',
		maxWidth: '500px',
		margin: '10px 0 0',
	},
	main: {
		background: '#FFFFFF',
		position: 'relative',
		zIndex: '3',
	},
	mainRaised: {
		margin: '-60px 30px 0px',
		borderRadius: '6px',
		boxShadow:
			'0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
	},
	link: {
		textDecoration: 'none',
	},
	textCenter: {
		textAlign: 'center',
	},
	root: {
		flexGrow: 1,
		display: 'flex',
	},
};

export const useStyles = makeStyles({
	button1: {
		'& .MuiButton-endIcon': {
			position: 'absolute',
			right: 16,
		},
		fontSize: '18px',
		fontWeight: 700,
		maxWidth: '90%',
		borderRadius: '25px',
	},
	acceptButton: {
		marginTop: '50px',
		textAlign: 'center',
	},
	changePassword: {
		color: '#FFE700',
		fontSize: '25px',
	},
	form: {
		color: 'white',
	},
	formContainer: {
		padding: '20px',
	},
	rate: {
		display: 'inline-block',
		paddingLeft: '2px',
		verticalAlign: 'text-bottom',
		fontSize: '16px',
		color: '#FFE700',
	},
	shopName: {
		fontSize: '18px',
		fontWeight: 700,
	},
	profileName: {
		color: '#FFE700',
		margin: '30px',
		fontSize: '21px',
		fontWeight: 700,
	},
	profileDetails: {
		color: '#FFE500',
		fontWeight: 300,
		fontSize: '16px',
	},
	shopLogo: {
		borderRadius: '50%',
		width: '50px',
		height: '50px',
		verticalAlign: 'middle',
		display: 'inline-block',
		marginRight: '5%',
	},
	profileShopLogo: {
		borderRadius: '50%',
		width: '150px',
		height: '150px',
		verticalAlign: 'middle',
		display: 'inline-block',
		margin: 'auto',
	},
	profileContainer: {
		textAlign: 'center',
	},
	userName: {
		verticalAlign: 'middle',
		display: 'inline-block',
		color: '#FFE700',
		fontSize: '18px',
		fontWeight: 700,
	},
	list: {
		color: 'white',
	},
	listItem: {
		color: 'white',
		'&:hover': {
			color: '#FFE700',
		},
	},
	listItemLogout: {
		color: '#D0021B',
		fontWeight: 500,
		fontSize: '16px',
		'&:hover': {
			color: 'grey',
		},
	},
	icon: {
		color: 'rgb(255,255,255,0.5)',
	},
	divider: {
		backgroundColor: 'white',
	},
	shopDivider: {
		backgroundColor: '#FFE700',
	},
	yellow: {
		color: '#FFE700',
	},
	grey: {
		color: 'rgb(255,255,255,0.5)',
	},
	backdrop: {
		zIndex: 10,
		color: '#FFFFFF',
	},
	logo: {
		backgroundColor: '#222222',
		textAlign: 'center',
		padding: '9px',
		verticalAlign: 'middle',
	},
	nav: {
		backgroundColor: '#000000',
	},
	navButton: {
		color: 'rgba(255,255,255,0.90)',
	},
	container: {
		paddingTop: '0px',
	},
	brand: {
		color: '#FFFFFF',
		textAlign: 'left',
	},
	title: {
		fontSize: '4.2rem',
		fontWeight: 600,
		display: 'inline-block',
		position: 'relative',
	},
	subtitle: {
		fontSize: '1.313rem',
		maxWidth: '500px',
		margin: '10px 0 0',
	},
	main: {
		background: '#FFFFFF',
		position: 'relative',
		zIndex: 3,
	},
	mainRaised: {
		margin: '-60px 30px 0px',
		borderRadius: '6px',
		boxShadow:
			'0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
	},
	link: {
		textDecoration: 'none',
	},
	textCenter: {
		textAlign: 'center',
	},
	background: {
		backgroundColor: '#222222',
		backgroundSize: 'cover',
		backgroundPosition: 'top center',
		minHeight: 'calc(100vh - 109px)',
		height: 'auto',
	},
});

export default componentsStyle;
