import { Box, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import homePageLogo from '../../images/logo_automate_alt_yellow.png';

const HomePage = () => (
	<>
		<img
			src={homePageLogo}
			alt='Logo'
			width='100%'
			style={{ margin: '200px 0 50px 0' }}
		/>
		<Box p='10px'>
			<Button
				component={Link}
				to='/login'
				size='large'
				color='secondary'
				variant='outlined'
				style={{ width: '45%' }}>
				Login
			</Button>
			<Button
				// component={Link}
				// to='/register'
				size='large'
				color='secondary'
				variant='outlined'
				style={{ width: '45%', float: 'right' }}>
				Register
			</Button>
		</Box>
	</>
);

export default HomePage;
