import { CheckCircle } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Link, useLocation } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';

const useStyles = makeStyles({
	button2: {
		marginBottom: '30px',
	},
	jobDetails: {
		fontSize: '20px',
		marginTop: '30px',
	},
	job: {
		fontSize: '30px',
	},
	tick: {
		fontSize: '150px',
		margin: '20px',
	},
	gridContainer: {
		textAlign: 'center',
		height: '100vh',
		weight: '100vw',
		color: 'white',
	},
	textField: {
		maxWidth: '20px',
		display: 'inline-block',
		margin: '20px',
	},
	img: {
		height: '50px',
		verticalAlign: 'middle',
		padding: '10px',
	},
	yellow: {
		color: '#FFE700',
	},
});

const JobDonePage = () => {
	const { state } = useLocation() as any;
	const classes = useStyles();

	return (
		<Grid
			container
			className={classes.gridContainer}
			justifyContent='center'
			alignItems='center'>
			<Grid item xs={4}>
				<CheckCircle className={classes.tick} color='primary' />
				<br />
				<span className={classes.job}>{state.jobTitle}</span>
				<br />
				<p className={classes.jobDetails}>{state.jobDetails}</p>
				<Link to={state.nextPath}>
					<Button
						className={classes.button2}
						color='primary'
						variant='contained'
						size='large'>
						完成
					</Button>
				</Link>
			</Grid>
		</Grid>
	);
};

export default JobDonePage;
