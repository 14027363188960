/* eslint-disable react/no-unused-prop-types */
import { useState } from 'react';
import moment from 'moment';
import 'moment/min/locales';
import { ArrowBack, Info, LocalOffer } from '@material-ui/icons';
import { useHistory, useParams } from 'react-router-dom';
import {
	Button,
	Drawer,
	Grid,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableRow,
} from '@material-ui/core';
import 'sendbird-uikit/dist/index.css';
import { useTranslation } from 'react-i18next';
import automateLogo from 'images/logo_automate_alt_yellow.png';
import { useStyles } from 'theme/orderPage';
import { useViewOrder } from 'utils/orders';
import getServiceDetail from './panelActions/getServiceDetail';
import getOrderDetail from './panelActions/getOrderDetail';

const carLogoPath =
	'https://images.automate-app.com/app/automate_pro/car_emblem_mechanic_invert/';

const WaitingConfirmationPanel = () => {
	const { orderId } = useParams<{ shopId: string; orderId: string }>();
	const [drawer, setDrawer] = useState(false);
	const history = useHistory();
	const classes = useStyles();
	const viewOrder = useViewOrder(orderId);
	const order = (viewOrder && getOrderDetail(viewOrder)) || [];
	const services = (viewOrder && getServiceDetail(viewOrder)) || [];
	const totalPrice = services.reduce(
		(acc: number, service: any) => service.price + acc,
		0
	);
	const { t } = useTranslation(['Order', 'Moment']);
	moment.locale(t('Moment:lang'));

	const toggleDrawer = () => setDrawer(!drawer);

	const getServices = services.map((service: any) => (
		<TableRow key={`${service.name}-${service.price}`}>
			<TableCell colSpan={3} className={classes.tableCell}>
				<div className={classes.serviceName}>
					{service.name}
					{order.orderType === 'quotation' ? (
						<div style={{ float: 'right' }}>
							<span className={classes.price}>${service.price}</span>
						</div>
					) : (
						<br />
					)}
				</div>
				<div className={classes.serviceDetails}>{service.serviceDetails}</div>
			</TableCell>
		</TableRow>
	));

	const getOrderType = () => {
		if (order.orderType === 'prepaid_fixed_price') {
			return (
				<TableRow key='prepaid_fixed_price'>
					<TableCell className={classes.orderType} colSpan={3}>
						<div>
							<img className={classes.img} src={automateLogo} alt='Logo' />
							{t('Fixed Price Service')}
						</div>
					</TableCell>
				</TableRow>
			);
		}
		if (order.orderType === 'offer') {
			return (
				<TableRow key='offer'>
					<TableCell
						className={classes.orderType}
						colSpan={3}
						component='th'
						scope='row'>
						<div>
							<LocalOffer />
							{t('Offer')}
						</div>
					</TableCell>
				</TableRow>
			);
		}
		return null;
	};

	const getTotalPrice = order?.orderType === 'quotation' && (
		<TableRow key='quotationTotal'>
			<TableCell
				colSpan={2}
				className={classes.table}
				component='th'
				scope='row'>
				<div className={classes.priceLabel}>
					{t('Total')}
					<Info
						onClick={toggleDrawer}
						className={classes.info}
						color='secondary'
					/>
				</div>
			</TableCell>
			<TableCell className={classes.totalPrice} align='right'>
				${totalPrice}
			</TableCell>
		</TableRow>
	);

	return (
		<>
			<Grid container justifyContent='center'>
				<Grid item xs={12}>
					<IconButton onClick={history.goBack} className={classes.yellow}>
						<ArrowBack />
					</IconButton>
					<span className={classes.yellow}>{t('Order Detail')}</span>
					<br />
					<div className={classes.car}>
						{order?.carLogo && (
							<img
								className={classes.img}
								src={carLogoPath + order.carLogo}
								alt='Car Logo'
							/>
						)}
						<div className={classes.carModel}>{order.car}</div>
						<div className={classes.carLicensePlate}>{order.licensePlate}</div>
						<div className={classes.carOwner}>{order.name}</div>
					</div>
					<div className={classes.bookedDate}>
						<div className={classes.preferDate}>{t('Order Date')}</div>
						<div className={classes.date}>
							{moment(order.preferDate).format('MMM Do,dddd')}
						</div>
					</div>
					<Table className={classes.table} aria-label='New Order'>
						<TableBody>
							{getOrderType()}
							{getServices}
							{order.orderType === 'quotation' && getTotalPrice}
						</TableBody>
					</Table>
					<div className={classes.skipButton}>
						<span className={classes.waitingButton}>
							{t("Waiting owner's reply")}
						</span>
					</div>
				</Grid>
				<Drawer anchor='bottom' open={drawer} onClose={toggleDrawer}>
					<Grid container className={classes.drawer} justifyContent='center'>
						<Grid item xs={6}>
							<Table className={classes.table} aria-label='New Order'>
								<TableBody className={classes.drawerCell}>
									<TableRow>
										<TableCell
											className={classes.table}
											component='th'
											scope='row'>
											{t('Total')}
										</TableCell>
										<TableCell className={classes.totalPrice} align='right'>
											${Number(totalPrice).toFixed(2)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell
											className={classes.table}
											component='th'
											scope='row'>
											{`${t('Fee')}(5%)`}
										</TableCell>
										<TableCell className={classes.totalPrice} align='right'>
											-${Number(totalPrice * 0.05).toFixed(2)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell
											className={classes.yellow}
											component='th'
											scope='row'>
											{t('Net Income')}
										</TableCell>
										<TableCell
											className={`${classes.totalPrice} ${classes.yellow}`}
											align='right'>
											${Number(totalPrice * 0.95).toFixed(2)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell colSpan={2}>
											<Button
												className={classes.button4}
												color='secondary'
												variant='outlined'
												size='large'
												onClick={toggleDrawer}
												fullWidth>
												{t('Close')}
											</Button>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</Grid>
					</Grid>
				</Drawer>
			</Grid>
		</>
	);
};

export default WaitingConfirmationPanel;
