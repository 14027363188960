export default {
	yellow: '#FFE700',
	headerBackBtn: '#FFE700',
	placeholderTextColor: 'rgba(255,255,255,0.25)',
	pageBackgroundColor: 'rgb(242, 242, 242)',
	black: '#000000',
	white: 'rgba(255,255,255,0.90)',
	borderGrayColor: 'rgba(0,0,0,0.07)',
	bubbleLightGray: '#F2F2F2',
	bubbleGray: '#D2D2D2',
	bubbleDarkYellow: '#E7D100',
	textBlack: '#222222',
	appBackgroundColor: '#222222',
};
