/* eslint-disable react/jsx-props-no-spreading */
import DateFnsUtils from '@date-io/date-fns';
import {
	Box,
	Button,
	Drawer,
	Grid,
	IconButton,
	Input,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Tabs,
} from '@material-ui/core';
import {
	ArrowBack,
	ArrowForward,
	FiberManualRecord,
	Info,
	RemoveCircle,
} from '@material-ui/icons';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import _ from 'lodash';
import moment from 'moment';
import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import 'sendbird-uikit/dist/index.css';
import { useStyles } from 'theme/orderPage';
import { patchQuote, useViewOrder } from 'utils/orders';
import AddService from '../../AddService';
import CustomDialog from '../../CustomDialog';
import DiscardWithPopUp from '../../DiscardButton';
import ChatRoom from '../chatroom/ChatRoom';
import getOrderDetail from './panelActions/getOrderDetail';
import getServiceDetail from './panelActions/getServiceDetail';

const carLogoPath =
	'https://images.automate-app.com/app/automate_pro/car_emblem_mechanic_invert/';

export interface TabPanelProps {
	children: ReactNode;
	value: Number;
	index: Number;
}

const TabPanel = ({ children, value, index }: TabPanelProps) => (
	<div
		role='tabpanel'
		hidden={value !== index}
		id={`order-tabpanel-${index}`}
		aria-labelledby={`check-order-tab-${index}`}>
		{value === index && children}
	</div>
);

const a11yProps = (index: Number) => ({
	id: `horizontal-tab-${index}`,
	'aria-controls': `horizontal-tabpanel-${index}`,
});

const ScheduledOrderPage = () => {
	const { shopId, orderId } = useParams<{ shopId: string; orderId: string }>();

	const classes = useStyles();
	const history = useHistory();
	const { t } = useTranslation(['Order', 'Moment']);
	moment.locale(t('Moment:lang'));

	const [services, setServices] = useState([] as Array<any>);
	const [value, setValue] = useState(0);
	const [drawer, setDrawer] = useState(false);
	const [selectedDate, setSelectedDate] = useState(
		new Date() as MaterialUiPickersDate
	);
	const [ToggleEdit, setToggleEdit] = useState(false);
	const [InvalidPrice, setInvalidPrice] = useState(false);
	const [EmptyService, setEmptyService] = useState(false);
	const [InvalidTime, setInvalidTime] = useState(false);
	const [patchError, setPatchError] = useState(false);

	const handleChange = (_event: object, newValue: number) => setValue(newValue);
	const handleDateChange = (date: MaterialUiPickersDate) =>
		setSelectedDate(date);

	const toggleDrawer = () => setDrawer(!drawer);

	const handleQuotationPrice = (newPrice: string, index: number) => {
		if (/^[0-9\b]+$/.test(newPrice)) {
			const tempList = _.cloneDeep(services);
			tempList[index].price = parseInt(newPrice, 10);
			setServices(tempList);
		}
	};

	const viewOrder = useViewOrder(orderId);
	const orderDetail = (viewOrder && getOrderDetail(viewOrder)) || [];
	const serviceDetail = (viewOrder && getServiceDetail(viewOrder)) || [];

	useEffect(() => {
		if (_.isEmpty(services)) setServices(serviceDetail);
	}, [serviceDetail]);

	const totalPrice = services.reduce((acc, cur) => acc + cur.price, 0);

	const getTotalPrice = (
		<TableRow key='totalPrice' className={classes.tableCell}>
			<TableCell
				colSpan={3}
				component='th'
				scope='row'
				className={classes.table}>
				<Box display='flex' alignItems='center' className={classes.priceLabel}>
					<Box flexGrow={1} display='flex' alignItems='center'>
						{t('Total')}
						<Info
							onClick={toggleDrawer}
							className={classes.info}
							color='secondary'
							style={{ cursor: 'pointer' }}
						/>
					</Box>
					<div className={classes.totalPrice}>${totalPrice}</div>
				</Box>
			</TableCell>
		</TableRow>
	);

	const getChangedDate = (
		<TableRow key='changeDate' className={classes.tableCell}>
			<TableCell colSpan={2} component='th' scope='row'>
				<div className={classes.priceLabel}>{t('Expected finish date')}</div>
			</TableCell>
			<TableCell className={classes.totalPrice} align='right'>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<KeyboardDatePicker
						className={classes.datePicker}
						margin='normal'
						id='date-picker-dialog'
						label={t('Pick date')}
						format='MM/dd/yyyy'
						value={selectedDate}
						onChange={handleDateChange}
						KeyboardButtonProps={{
							'aria-label': 'change date',
						}}
					/>
				</MuiPickersUtilsProvider>
			</TableCell>
		</TableRow>
	);

	const handleToggle = () => setToggleEdit(!ToggleEdit);

	const handleSumbit = () => {
		if (services.length === 0) return setEmptyService(true);
		if (selectedDate === null) return setInvalidTime(true);

		const haveZero = services.some((curr) => curr.price === 0);
		if (haveZero) return setInvalidPrice(true);

		const updated = serviceDetail.map((oldService: any) => {
			const equalItem = services.filter(
				(newService) => oldService.id === newService.id
			);

			if (_.isEmpty(equalItem))
				// empty means item is deleted
				return { status: 'inactive', service_id: oldService.id };
			return {
				status: 'active',
				service_id: oldService.id,
				price: equalItem[0].price,
			};
		});

		const newQuotes = services
			.filter((i) => !updated.map((j: any) => j.service_id).includes(i.id))
			.map((newQ) => ({ service_category_id: newQ.id, price: newQ.price }));

		const patchData = {
			new_quotations: newQuotes,
			updated_quotations: updated,
			target_completion_date: selectedDate.getTime(),
			on_behalf_shop: shopId,
		};

		// console.log(patchData, orderDetail);
		return patchQuote(orderDetail.quotationId, patchData)
			.then(() => {
				const state = {
					jobTitle: '遞交了報價',
					jobDetails:
						'成功遞交初步報價。\n車主檢閲後如選擇你的報價，你將會收到通知，謝謝。',
					nextPath: '/processing-order',
				};
				history.replace('/job-done', state);
			})
			.catch(() => setPatchError(true));
	};

	const submitButton = (
		<div className={classes.skipButton}>
			<Button
				onClick={handleSumbit}
				className={classes.button1}
				color='primary'
				variant='contained'
				size='large'
				endIcon={<ArrowForward />}
				fullWidth>
				{t('Submit Quotation')}
			</Button>
		</div>
	);

	const confirmChangeButton = (
		<div className={classes.skipButton}>
			<Button
				onClick={handleToggle}
				className={classes.button1}
				color='primary'
				variant='contained'
				size='large'
				fullWidth>
				{t('Confirm Change')}
			</Button>
		</div>
	);

	const removeServiceByIndex = (index: number) => {
		const tempList = [...services];
		tempList.splice(index, 1);
		setServices(tempList);
	};

	const handleCancel = () => {
		setToggleEdit(!ToggleEdit);
		setServices([...serviceDetail]);
	};

	const editButton = (
		<div className={classes.skipButton}>
			<Button
				onClick={handleToggle}
				className={classes.button2}
				color='secondary'
				variant='outlined'
				size='large'
				fullWidth>
				{t('Edit')}
			</Button>
		</div>
	);

	const cancelButton = (
		<div className={classes.skipButton}>
			<Button
				onClick={handleCancel}
				className={classes.button2}
				color='secondary'
				variant='outlined'
				size='large'
				fullWidth>
				{t('Cancel')}
			</Button>
		</div>
	);

	const buttons = ToggleEdit ? (
		<>
			{confirmChangeButton}
			{cancelButton}
		</>
	) : (
		<>
			{submitButton}
			{editButton}
			<DiscardWithPopUp
				redirectLink='/processing-order'
				orderId={orderDetail.orderId}
			/>
		</>
	);

	const getServices = services.map((service, index: number) => (
		<TableRow key={service.id} className={classes.tableCell}>
			<TableCell colSpan={3}>
				<Box display='flex' alignItems='center' flexDirection='row'>
					{ToggleEdit && (
						<IconButton
							className={classes.iconLeft}
							color='secondary'
							onClick={() => removeServiceByIndex(index)}>
							<RemoveCircle />
						</IconButton>
					)}
					<Box flexGrow={1} className={classes.serviceName}>
						{service.name}
					</Box>
					{ToggleEdit ? (
						<Input
							onInput={(event: ChangeEvent<HTMLInputElement>) =>
								handleQuotationPrice(event.target.value, index)
							}
							value={service.price}
							inputProps={{
								maxLength: 6,
								style: { textAlign: 'right', color: 'white' },
							}}
							className={classes.inputQuotation}
							color='secondary'
							disableUnderline
							placeholder='$0'
						/>
					) : (
						<div className={classes.price}>${service.price}</div>
					)}
				</Box>
			</TableCell>
		</TableRow>
	));

	const errorDialog = (
		<>
			{InvalidPrice && (
				<CustomDialog
					title='價錢無效'
					text='請輸入有效價錢'
					button='好的'
					dispatch={setInvalidPrice}
				/>
			)}
			{EmptyService && (
				<CustomDialog
					title='柯打無效'
					text='請輸入有效柯打'
					button='好的'
					dispatch={setEmptyService}
				/>
			)}
			{InvalidTime && (
				<CustomDialog
					title='時間無效'
					text='請輸入有效時間'
					button='好的'
					dispatch={setInvalidTime}
				/>
			)}
			{patchError && (
				<CustomDialog
					title='Server-side Error'
					text='An error has occured'
					button='OK'
					dispatch={setPatchError}
				/>
			)}
		</>
	);

	return (
		<Grid container>
			<Grid item xs>
				<IconButton onClick={history.goBack} className={classes.yellow}>
					<ArrowBack />
				</IconButton>
				<span className={classes.yellow}>{t('Order Detail')}</span>
				<br />
				<div className={classes.car}>
					{orderDetail.carLogo && (
						<img
							className={classes.img}
							src={carLogoPath + orderDetail.carLogo}
							alt='Car Logo'
						/>
					)}
					<div className={classes.carModel}>{orderDetail.car}</div>
					<div className={classes.carLicensePlate}>
						{orderDetail.licensePlate}
					</div>
					<div className={classes.carOwner}>{orderDetail.name}</div>
				</div>
				<Tabs
					className={classes.messageTab}
					value={value}
					onChange={handleChange}
					indicatorColor='primary'
					textColor='primary'
					variant='fullWidth'>
					<Tab
						className={classes.messageTabs}
						label={t('Detail')}
						{...a11yProps(0)}
					/>
					{orderDetail.chatUnread === true ? (
						<Tab
							className={classes.messageTabs}
							label={
								<>
									<FiberManualRecord
										style={{ color: '#4aadff' }}
										viewBox='0 -5 30 30'
									/>
									{t('Message')}
								</>
							}
							{...a11yProps(1)}
						/>
					) : (
						<Tab
							className={classes.messageTabs}
							label={t('Message')}
							{...a11yProps(1)}
						/>
					)}
				</Tabs>
				<TabPanel value={value} index={0}>
					<div className={classes.bookedDate}>
						<div className={classes.preferDate}>{t('Order Date')}</div>
						<div className={classes.date}>
							{moment(orderDetail.preferDate).format('MMM Do,dddd')}
						</div>
					</div>
					<Table className={classes.table} aria-label='New Order'>
						<TableBody>
							{getServices}
							{ToggleEdit && <AddService setServices={setServices} />}
							{getTotalPrice}
							{getChangedDate}
							{errorDialog}
						</TableBody>
					</Table>
					{buttons}
				</TabPanel>
				<TabPanel value={value} index={1}>
					<ChatRoom chatroomId={orderDetail.chatroomId} />
				</TabPanel>
			</Grid>
			<Drawer anchor='bottom' open={drawer} onClose={toggleDrawer}>
				<Grid container justifyContent='center' className={classes.drawer}>
					<Grid item xs={6}>
						<Table className={classes.table} aria-label='New Order'>
							<TableBody className={classes.drawerCell}>
								<TableRow key='sum'>
									<TableCell
										className={classes.table}
										component='th'
										scope='row'>
										{t('Total')}
									</TableCell>
									<TableCell className={classes.totalPrice} align='right'>
										${Number(totalPrice).toFixed(2)}
									</TableCell>
								</TableRow>
								<TableRow key='fee'>
									<TableCell
										className={classes.table}
										component='th'
										scope='row'>
										{`${t('Fee')}(5%)`}
									</TableCell>
									<TableCell className={classes.totalPrice} align='right'>
										-${Number(totalPrice * 0.05).toFixed(2)}
									</TableCell>
								</TableRow>
								<TableRow key='income'>
									<TableCell
										className={classes.yellow}
										component='th'
										scope='row'>
										{t('Net Income')}
									</TableCell>
									<TableCell
										className={`${classes.totalPrice} ${classes.yellow}`}
										align='right'>
										${Number(totalPrice * 0.95).toFixed(2)}
									</TableCell>
								</TableRow>
								<TableRow key='closeBTN'>
									<TableCell colSpan={2}>
										<Button
											className={classes.button4}
											color='secondary'
											variant='outlined'
											size='large'
											onClick={toggleDrawer}
											fullWidth>
											{t('Close')}
										</Button>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</Grid>
				</Grid>
			</Drawer>
		</Grid>
	);
};

export default ScheduledOrderPage;
