import { useState } from 'react';
import { ArrowBack, ChevronRight } from '@material-ui/icons';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from '@material-ui/core';
import { logout } from 'utils/auth';
import { useHistory } from 'react-router-dom';
import { useProfile } from 'utils/users';
import defaultLogo from 'images/placeholder_user_yellow.png';
import { useStyles } from 'theme/components';
import { useSetRecoilState } from 'recoil';
import RecoilStates from '../../RecoilStates';

const logoPath = 'https://images.automate-app.com';

const ProfilePage = () => {
	const [Logout, setLogout] = useState(false);
	const setLoggedIn = useSetRecoilState(RecoilStates.login);
	const history = useHistory();
	const { data } = useProfile();
	const profile = {
		name: data?.mechanic?.name,
		email: data?.mechanic?.email,
		mobile: data?.mechanic?.mobile,
	};

	const logo = localStorage.userLogo
		? `${logoPath}${localStorage.userLogo}`
		: defaultLogo;

	const classes = useStyles();
	const handleLogoutOpen = () => setLogout(true);
	const handleClose = () => setLogout(false);

	const handleLogout = () => {
		logout();
		setLoggedIn(false);
	};

	return (
		<>
			<Dialog
				open={Logout}
				color='primary'
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'>
				<DialogTitle id='alert-dialog-title'>登出</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						是否要登出？
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} autoFocus>
						取消
					</Button>
					<Button onClick={handleLogout}>確定</Button>
				</DialogActions>
			</Dialog>

			<Grid container justifyContent='center'>
				<Grid item xs={4}>
					<IconButton
						className={classes.yellow}
						onClick={() => history.goBack()}>
						<ArrowBack />
					</IconButton>
					<span className={classes.yellow}>{profile.name}</span>
					<div
						className={classes.profileContainer}
						style={{ marginTop: '5vh' }}>
						<img className={classes.profileShopLogo} src={logo} alt='Logo' />
						<div className={classes.profileName}>{profile.name}</div>
						<div className={classes.profileDetails}>{profile.email}</div>
						<br />
						<div className={classes.profileDetails}>{profile.mobile}</div>
					</div>
					<List>
						<ListItem
							className={classes.listItem}
							button
							onClick={() => history.push('/edit-profile')}
							key='edit-profile'>
							<ListItemText>修改個人資料</ListItemText>
							<ListItemIcon className={classes.icon}>
								<ChevronRight />
							</ListItemIcon>
						</ListItem>
						<ListItem
							className={classes.listItem}
							onClick={() => history.push('/change-password')}
							button
							key='edit-password'>
							<ListItemText>更改密碼</ListItemText>
							<ListItemIcon className={classes.icon}>
								<ChevronRight />
							</ListItemIcon>
						</ListItem>
						<ListItem
							className={classes.listItemLogout}
							onClick={handleLogoutOpen}
							button
							key='logout'>
							<ListItemText>登出</ListItemText>
							<ListItemIcon className={classes.icon}>
								<ChevronRight />
							</ListItemIcon>
						</ListItem>
					</List>
				</Grid>
			</Grid>
		</>
	);
};

export default ProfilePage;
