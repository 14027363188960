import { ChangeEvent, useEffect, useState } from 'react';
import moment from 'moment';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Drawer,
	Grid,
	IconButton,
	TextField,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Box,
} from '@material-ui/core';
import { ArrowBack, ArrowForward, Info, LocalOffer } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import automateLogo from 'images/logo_automate_alt_yellow.png';
import { useStyles } from 'theme/orderPage';
import {
	useViewOrder,
	createQuote,
	skipOrder,
	acceptFixedPriceOrder,
} from 'utils/orders';
import getOrderDetail from './panelActions/getOrderDetail';
import getServiceDetail from './panelActions/getServiceDetail';
import CustomDialog from '../../CustomDialog';

const carLogoPath =
	'https://images.automate-app.com/app/automate_pro/car_emblem_mechanic_invert/';

const OrderPanel = () => {
	const { shopId, orderId } = useParams<{ shopId: string; orderId: string }>();
	const [open, setOpen] = useState(false);
	const [openInvalidPrice, setOpenInvalidPrice] = useState(false);
	const [drawer, setDrawer] = useState(false);
	const [quotation, setQuotation] = useState(false);
	const [confirmQuotation, setConfirmQuotation] = useState(false);
	const [error, setError] = useState(false);
	const classes = useStyles();
	const history = useHistory();
	const [quotationPrice, setQuotationPrice] = useState([] as any);

	const { t } = useTranslation(['Order', 'Moment']);
	const viewOrder = useViewOrder(orderId);
	const order = (viewOrder && getOrderDetail(viewOrder)) || [];
	const services = (viewOrder && getServiceDetail(viewOrder)) || [];
	moment.locale(t('Moment:lang'));

	useEffect(() => {
		if (services.length && !quotationPrice.length) {
			const { length } = services;
			const emptyArr = new Array(length);
			for (let i = 0; i < length; i += 1) emptyArr[i] = '';
			setQuotationPrice(emptyArr);
		}
	}, [services]);

	const toggleConfirmQuotation = () => {
		const anyZero = quotationPrice.some((item: number) => item === 0);
		const anyEmpty = quotationPrice.length !== services.length;
		if (anyZero || anyEmpty) {
			setOpenInvalidPrice(true);
		} else {
			setConfirmQuotation(!confirmQuotation);
		}
	};

	const toggleDrawer = () => setDrawer(!drawer);
	const toggleQuotation = () => setQuotation(!quotation);
	const handleClickOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const handleInvalidPriceClose = () => setOpenInvalidPrice(false);
	const handleQuotationPrice = (value: string, index: number) => {
		if (/^[0-9]*$/.test(value)) {
			const list = [...quotationPrice];
			list[index] = value;
			setQuotationPrice(list);
		}
	};

	const getServices = services.map((service: any, index: number) => (
		// eslint-disable-next-line react/no-array-index-key
		<TableRow key={index}>
			<TableCell colSpan={3} className={classes.tableCell}>
				<div className={classes.serviceName}>{service.name}</div>
				{order.orderType !== 'quotation' && (
					<div className={classes.serviceDetails}>{service.serviceDetails}</div>
				)}

				{order.orderType === 'prepaid_fixed_price' && (
					<div style={{ float: 'right' }}>
						<span className={classes.price}> {service.price}</span>
					</div>
				)}

				{quotation === true && confirmQuotation === false && (
					<div style={{ float: 'right' }}>
						<TextField
							onInput={(event: ChangeEvent<HTMLInputElement>) =>
								handleQuotationPrice(event.target.value, index)
							}
							value={quotationPrice[index]}
							InputProps={{ disableUnderline: true }}
							// eslint-disable-next-line react/jsx-no-duplicate-props
							inputProps={{
								maxLength: 6,
								style: { textAlign: 'right', color: 'white' },
							}}
							className={classes.inputQuotation}
							color='secondary'
							placeholder='$0'
						/>
					</div>
				)}

				{confirmQuotation === true && (
					<div style={{ float: 'right' }}>
						<span className={classes.price}>${quotationPrice[index]}</span>
					</div>
				)}
			</TableCell>
		</TableRow>
	));

	const getOrderType = () => {
		if (order.orderType === 'prepaid_fixed_price')
			return (
				<TableRow key='prepaid_fixed_price'>
					<TableCell
						className={classes.orderType}
						colSpan={3}
						component='th'
						scope='row'>
						<Box display='flex'>
							<img className={classes.img} src={automateLogo} alt='Logo' />
							{t('Fixed Price Service')}
						</Box>
					</TableCell>
				</TableRow>
			);

		if (order.orderType === 'offer')
			return (
				<TableRow key='offer'>
					<TableCell
						className={classes.orderType}
						colSpan={3}
						component='th'
						scope='row'>
						<Box display='flex' alignItems='center'>
							<LocalOffer />
							{t('Offer')}
						</Box>
					</TableCell>
				</TableRow>
			);

		if (order.orderType === 'quotation')
			return (
				<TableRow key='quotation'>
					<TableCell
						className={classes.orderType}
						colSpan={3}
						component='th'
						scope='row'>
						<div>
							<img className={classes.img} src={automateLogo} alt='Logo' />
							{t('Quotation Service')}
						</div>
					</TableCell>
				</TableRow>
			);

		return null;
	};

	const getTotalPrice = order.orderType === 'quotation' && (
		<TableRow key='totalPrice'>
			<TableCell
				colSpan={2}
				className={classes.table}
				component='th'
				scope='row'>
				<div className={classes.priceLabel}>
					{t('Total')}
					<Info
						onClick={toggleDrawer}
						className={classes.info}
						color='secondary'
					/>
				</div>
			</TableCell>
			<TableCell className={classes.totalPrice} align='right'>
				$
				{quotationPrice.reduce(
					(acc: number, value: string) => acc + Number(value),
					0
				)}
			</TableCell>
		</TableRow>
	);

	const handleSubmitQuote = () => {
		const anyEmpty = quotationPrice.some((elem: string) => elem === '');
		if (anyEmpty) return setError(true);
		return (
			createQuote(orderId, shopId, quotationPrice, services)
				.then(() => {
					const pathname = '/job-done';
					const state = {
						jobTitle: '遞交了報價',
						jobDetails:
							'成功遞交初步報價。\n車主檢閲後如選擇你的報價，你將會收到通知，謝謝。',
						nextPath: '/new-order',
					};
					history.replace(pathname, state);
				})
				// eslint-disable-next-line no-console
				.catch(() => console.error('An error has occured'))
		);
	};

	const getConfirmQuotationButton = (
		<>
			<div className={classes.acceptButton}>
				<Button
					onClick={handleSubmitQuote}
					className={classes.button1}
					color='primary'
					variant='contained'
					size='large'
					endIcon={<ArrowForward />}
					fullWidth>
					{t('Submit Quotation')}
				</Button>
			</div>
			<div className={classes.skipButton}>
				<Button
					className={classes.button2}
					color='secondary'
					variant='outlined'
					size='large'
					onClick={toggleConfirmQuotation}
					fullWidth>
					{t('Edit')}
				</Button>
			</div>
		</>
	);

	const handleAcceptOrder = () => {
		acceptFixedPriceOrder(orderId, shopId)
			.then(() => {
				const pathname = '/job-done';
				const state = {
					jobTitle: '確認了柯打',
					jobDetails:
						'經已成功確認柯打及預約日期，車主將收到通知，你亦可以繼續以短信與車主溝通。',
					nextPath: '/new-order',
				};
				history.replace(pathname, state);
			})
			// eslint-disable-next-line no-console
			.catch(() => console.error('An error has occured'));
	};

	const getAcceptButton = (
		<div className={classes.acceptButton}>
			<Button
				onClick={handleAcceptOrder}
				className={classes.button1}
				color='primary'
				variant='contained'
				size='large'
				endIcon={<ArrowForward />}
				fullWidth>
				{t('Accept Order')}
			</Button>
		</div>
	);

	const getQuotationButton =
		quotation === true ? (
			<div className={classes.skipButton}>
				<Button
					className={classes.button2}
					color='secondary'
					variant='outlined'
					size='large'
					fullWidth
					onClick={toggleConfirmQuotation}>
					{t('Continue')}
				</Button>
			</div>
		) : (
			<div className={classes.skipButton}>
				<Button
					className={classes.button2}
					color='secondary'
					variant='outlined'
					size='large'
					fullWidth
					onClick={toggleQuotation}>
					{t('Quote')}
				</Button>
			</div>
		);

	return (
		<>
			<Dialog
				open={openInvalidPrice}
				color='primary'
				onClose={handleInvalidPriceClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'>
				<DialogTitle id='alert-dialog-title'>{t('Invalid Price')}</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						{t('Please input a valid price')}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleInvalidPriceClose} autoFocus>
						{t('OK')}
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={open}
				color='primary'
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'>
				<DialogTitle id='alert-dialog-title'>{t('Skip Order')}</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						{t('Confirm skip order?')}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} autoFocus>
						{t('No')}
					</Button>
					<Link to='/new-order' onClick={() => skipOrder(order.orderId)}>
						<Button onClick={handleClose}>{t('Yes')}</Button>
					</Link>
				</DialogActions>
			</Dialog>
			{error && (
				<CustomDialog
					button={t('OK')}
					title={t(t('Invalid Price'))}
					text={t('Please input a valid price')}
					dispatch={setError}
				/>
			)}

			<Grid container justifyContent='center'>
				<Grid item xs={12}>
					<IconButton onClick={history.goBack} className={classes.yellow}>
						<ArrowBack />
					</IconButton>
					<span className={classes.yellow}>{t('Order Detail')}</span>
					<br />
					<div className={classes.car}>
						{order.carLogo && (
							<img
								className={classes.img}
								src={`${carLogoPath}${order.carLogo}`}
								alt='Car Logo'
							/>
						)}
						<div className={classes.carModel}>{order.car}</div>
						<div className={classes.carLicensePlate}>{order.licensePlate}</div>
						<div className={classes.carOwner}>{order.name}</div>
					</div>
					<div className={classes.bookedDate}>
						<div className={classes.preferDate}>{t('Scheduled Date')}</div>
						<div className={classes.date}>
							{moment(order.preferDate).format('MMM Do,dddd')}
						</div>
					</div>
					<Table className={classes.table} aria-label='New Order'>
						<TableBody>
							{getOrderType()}
							{getServices}
							{confirmQuotation === true && getTotalPrice}
						</TableBody>
					</Table>
					{order.orderType !== 'quotation' &&
						confirmQuotation === false &&
						getAcceptButton}
					{order.orderType === 'quotation' &&
						confirmQuotation === false &&
						getQuotationButton}
					{confirmQuotation && getConfirmQuotationButton}
					{quotation === false && (
						<div className={classes.skipButton}>
							<Button
								className={classes.button2}
								color='secondary'
								variant='outlined'
								size='large'
								onClick={handleClickOpen}
								fullWidth>
								{t('Skip Order')}
							</Button>
						</div>
					)}
				</Grid>
				<Drawer anchor='bottom' open={drawer} onClose={toggleDrawer}>
					<Grid container className={classes.drawer} justifyContent='center'>
						<Grid item xs={5}>
							<Table className={classes.table} aria-label='New Order'>
								<TableBody className={classes.drawerCell}>
									<TableRow key='total'>
										<TableCell
											className={classes.table}
											component='th'
											scope='row'>
											{t('Total')}
										</TableCell>
										<TableCell className={classes.totalPrice} align='right'>
											$
											{Number(
												quotationPrice.reduce(
													(acc: number, value: string) => acc + Number(value),
													0
												)
											).toFixed(2)}
										</TableCell>
									</TableRow>
									<TableRow key='fee'>
										<TableCell
											className={classes.table}
											component='th'
											scope='row'>
											{`${t('Fee')}(5%)`}
										</TableCell>
										<TableCell className={classes.totalPrice} align='right'>
											-$
											{Number(
												quotationPrice.reduce(
													(acc: number, value: string) => acc + Number(value),
													0
												) * 0.05
											).toFixed(2)}
										</TableCell>
									</TableRow>
									<TableRow key='income'>
										<TableCell
											className={classes.yellow}
											component='th'
											scope='row'>
											{t('Net Income')}
										</TableCell>
										<TableCell
											className={`${classes.totalPrice} ${classes.yellow}`}
											align='right'>
											$
											{Number(
												quotationPrice.reduce(
													(acc: number, value: string) => acc + Number(value),
													0
												) * 0.95
											).toFixed(2)}
										</TableCell>
									</TableRow>
									<TableRow key='close'>
										<TableCell colSpan={2}>
											<Button
												className={classes.button4}
												color='secondary'
												variant='outlined'
												size='large'
												onClick={toggleDrawer}
												fullWidth>
												{t('Close')}
											</Button>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</Grid>
					</Grid>
				</Drawer>
			</Grid>
		</>
	);
};

export default OrderPanel;
