import { List, ListItem, ListItemText, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import langs from 'locales/ListOfLanguages';
import { changeLanguage } from 'utils/users';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';

const languages = Object.entries(langs).map(([key, value]) => ({
	code: key,
	name: value,
}));

const useStyles = makeStyles({
	list: { padding: '1em 1em' },
});

const LanguageSwitcher = () => {
	const { i18n } = useTranslation();
	const classes = useStyles();
	const [selectedLang, setSelectedLang] = useState(
		localStorage.getItem('language') ?? 'en'
	);

	const [selectedIndex, setSelectedIndex] = useState(
		_.findIndex(languages, { code: selectedLang })
	);

	const handleClick = (langCode: string, index: number) => {
		setSelectedIndex(index);
		setSelectedLang(langCode);
		i18n.changeLanguage(langCode);
		changeLanguage(langCode);
	};

	return (
		<Paper>
			<List className={classes.list}>
				{languages.map((lang, index) => (
					<ListItem
						button
						divider
						key={lang.name}
						selected={selectedIndex === index}
						onClick={() => handleClick(lang.code, index)}>
						<ListItemText primary={lang.name} />
					</ListItem>
				))}
			</List>
		</Paper>
	);
};

export default LanguageSwitcher;
