import useSWR from 'swr';
import api from '../api';

export const useProfile = () => {
	const fetcher = () =>
		api.user.getProfile(localStorage.automateJWT).then((data) => data);
	return useSWR('useProfile', fetcher);
};

export const changeLanguage = (language: string) => {
	// set language to localStorage
	localStorage.language = language;
	const fd = new FormData();
	fd.append('json', `{"settings": {"lang": "${language}"}}`);
	return api.user.updateProfile(fd);
};
