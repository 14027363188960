/* eslint-disable react/jsx-props-no-spreading */
import { Grid, Tab, Table, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import {
	useBookedOrders,
	useCompletedOrders,
	useProcessingOrders,
} from 'utils/orders';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmptyList from '../../EmptyList';
import { ordersRow } from './NewOrderTabs';

const useStyles = makeStyles({
	img: {
		width: '20px',
		marginLeft: '3px',
		marginRight: '12px',
		verticalAlign: 'middle',
		objectFit: 'contain',
	},
	root: {
		flexGrow: 1,
	},
	tabs: {
		width: '100%',
		maxWidth: 'max-content',
		color: 'white',
	},
	card: {
		padding: '1%',
	},
	tableCell: {
		borderBottom: '1px solid rgba(255, 255, 255, 0.15)',
	},
	dateCell: {
		color: 'rgba(255, 255, 255, 0.65)',
		backgroundColor: 'black',
		padding: '6px',
		fontSize: '16px',
		fontWeight: 300,
	},
	noPadding: {
		padding: '0',
	},
});

interface Props {
	shopId: string;
}

const ProcessingOrderTabs = (props: Props) => {
	const { shopId } = props;
	const classes = useStyles();
	const { t } = useTranslation('Tabs');

	const [value, setValue] = useState('one');
	const handleChange = (_event: any, newValue: string) => setValue(newValue);

	const processingOrders = useProcessingOrders(shopId);
	const bookedOrders = useBookedOrders(shopId);
	const completedOrders = useCompletedOrders(shopId);

	const getProcessingOrders = () =>
		ordersRow(processingOrders, shopId, classes, '/check-order-item');
	const renderProcessingOrders = processingOrders.length ? (
		<Table aria-label='Processing'>
			<TableBody>{getProcessingOrders()}</TableBody>
		</Table>
	) : (
		<EmptyList />
	);

	const getBookedOrders = () =>
		ordersRow(bookedOrders, shopId, classes, '/scheduled-order');
	const renderBookedOrders = bookedOrders.length ? (
		<Table aria-label='Booked'>
			<TableBody>{getBookedOrders()}</TableBody>
		</Table>
	) : (
		<EmptyList />
	);

	const getCompletedOrders = () =>
		ordersRow(completedOrders, shopId, classes, '/completed-order');
	const renderCompletedOrders = completedOrders.length ? (
		<Table aria-label='Completed'>
			<TableBody>{getCompletedOrders()}</TableBody>
		</Table>
	) : (
		<EmptyList />
	);

	return (
		<Grid container>
			<Grid item xs>
				<TabContext value={value}>
					<TabList
						onChange={handleChange}
						variant='fullWidth'
						indicatorColor='primary'
						textColor='primary'>
						<Tab label={t('tabScheduled')} value='one' />
						<Tab label={t('tabActive')} value='two' />
						<Tab label={t('tabCompleted')} value='three' />
					</TabList>
					<TabPanel className={classes.noPadding} value='one'>
						{renderBookedOrders}
					</TabPanel>
					<TabPanel className={classes.noPadding} value='two'>
						{renderProcessingOrders}
					</TabPanel>
					<TabPanel className={classes.noPadding} value='three'>
						{renderCompletedOrders}
					</TabPanel>
				</TabContext>
			</Grid>
		</Grid>
	);
};

export default ProcessingOrderTabs;
