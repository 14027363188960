import { Redirect, Route, Switch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Container } from '@material-ui/core';
import RecoilStates from './RecoilStates';
import COIPage from './components/pages/CheckOrderItemPage';
import COPage from './components/pages/CompletedOrderPage';
import JobDonePage from './components/pages/JobDonePage';
import LanguagePage from './components/pages/LanguagePage';
import MenuPage from './components/pages/MenuPage';
import NewOrderPage from './components/pages/NewOrderPage';
import OldOrderPage from './components/pages/OldOrderPage';
import OrderHistoryPage from './components/pages/OrderHistoryPage';
import OrderPage from './components/pages/OrderPage';
import PickUpPinPage from './components/pages/PickUpPinPage';
import PolicyPage from './components/pages/PolicyPage';
import ProcessingOrderPage from './components/pages/ProcessingOrderPage';
import ProfilePage from './components/pages/ProfilePage';
import SOPage from './components/pages/ScheduledOrderPage';
import WCPage from './components/pages/WaitingConfirmationPage';
import HeaderPostLogin from './components/navigation/HeaderPostLogin';
import HeaderPreLogin from './components/navigation/HeaderPreLogin';
import HomePage from './components/pages/HomePage';
import LoginPage from './components/pages/LoginPage';

// todo: fix push notification
// import { messaging } from './init-fcm';
// const registerPushListener = (pushNotification) =>
// 	navigator.serviceWorker.addEventListener('message', (data) =>
// 		pushNotification(
// 			data.data
// 				? data.data.message
// 				: data['firebase-messaging-msg-data'].data.message
// 		)
// 	);

const slugify = (path: string) => `${path}/:shopId/:orderId`;

const App = () =>
	useRecoilValue(RecoilStates.login) ? (
		<>
			<HeaderPostLogin />
			<Switch>
				<Route path={slugify('/order')} component={OrderPage} />
				<Route path={slugify('/old-order')} component={OldOrderPage} />
				<Route path={slugify('/check-order-item')} component={COIPage} />
				<Route path={slugify('/completed-order')} component={COPage} />
				<Route path={slugify('/waiting-confirmation')} component={WCPage} />
				<Route path={slugify('/scheduled-order')} component={SOPage} />
				<Route path={slugify('/pick-up')} component={PickUpPinPage} />
				<Route path='/new-order' component={NewOrderPage} />
				<Route path='/processing-order' component={ProcessingOrderPage} />
				<Route path='/menu' component={MenuPage} />
				<Route path='/order-history' component={OrderHistoryPage} />
				<Route path='/job-done' component={JobDonePage} />
				<Route path='/policy' component={PolicyPage} />
				<Route path='/profile' component={ProfilePage} />
				<Route path='/language' component={LanguagePage} />
				<Route path='/' render={() => <Redirect to='/new-order' />} />
			</Switch>
		</>
	) : (
		<Container maxWidth='sm'>
			<HeaderPreLogin />
			<Switch>
				<Route path='/login' component={LoginPage} />
				<Route path='/' exact component={HomePage} />
				<Route render={() => <Redirect to='/' />} />
			</Switch>
		</Container>
	);

export default App;
