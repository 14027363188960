import { Channel, SendBirdProvider } from 'sendbird-uikit';
// import CustomizedMessageInput from './CustomizedMessageInput';

const ChatRoom = ({ chatroomId }: { chatroomId: string }) => (
	<div style={{ height: '70vh' }}>
		<SendBirdProvider
			appId='DF318287-A9C2-456C-A347-3B8C182FF17D'
			userId={localStorage.mechanicId}>
			<div className='sendbird-app__wrap'>
				<div className='sendbird-app__conversation-wrap'>
					<Channel renderChatHeader={() => null} channelUrl={chatroomId} />
				</div>
			</div>
		</SendBirdProvider>
	</div>
);
export default ChatRoom;
