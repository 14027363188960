import { makeStyles } from '@material-ui/core/styles';
import { Tab } from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';
import { useRecoilState } from 'recoil';
import RecoilStates from 'RecoilStates';
import { useShopList } from 'utils/orders';
import { TabContext, TabList } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
	tabLabel: {
		color: 'rgb(255,255,255,0.5)',
		textAlign: 'left',
		'& .MuiTab-wrapper': {
			justifyContent: 'left',
		},
	},
	tabs: {
		'& .MuiTabs-indicator': {
			backgroundColor: 'transparent',
		},
		minWidth: '35%',
		maxWidth: 'max-content',
	},
});

interface SideNavProps {
	pathname?: string; // redirect path
}

const SideNav = ({ pathname = '/new-order' }: SideNavProps) => {
	const classes = useStyles();
	const { shopList } = useShopList();
	const history = useHistory();
	const [shopIndex, setShopIndex] = useRecoilState(RecoilStates.shopIndex);
	const handleChange = (_foo: any, value: string) => {
		setShopIndex(value);
		history.push(pathname);
	};

	const shopListTabLabel =
		shopList &&
		shopList.map((shop, index) => (
			<Tab
				key={shop.shopId}
				value={index.toString()}
				className={classes.tabLabel}
				icon={<ArrowRight fontSize='large' />}
				label={shop.shopName}
			/>
		));

	return (
		<TabContext value={shopIndex}>
			<TabList
				orientation='vertical'
				onChange={handleChange}
				className={classes.tabs}
				textColor='primary'>
				{shopListTabLabel}
			</TabList>
		</TabContext>
	);
};

export default SideNav;
