import { Grid } from '@material-ui/core';
import CustomBottomNavigation from '../navigation/BottomNavigation';
import ShopListTabs from './tabs/ShopListTabs';

const OrderHistoryPage = () => (
	<>
		<Grid container justifyContent='center'>
			<Grid item xs={12} md={6}>
				<ShopListTabs />
			</Grid>
		</Grid>
		<CustomBottomNavigation value='2' />
	</>
);

export default OrderHistoryPage;
