import IconButton from '@material-ui/core/IconButton';
import { ArrowBack } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Container, Typography } from '@material-ui/core';

const PolicyPage = () => {
	const location = useLocation() as any;
	const history = useHistory();

	return (
		<>
			<Container maxWidth='md'>
				<Box width='100%' height='min-content'>
					<Typography color='primary'>
						<IconButton onClick={history.goBack}>
							<ArrowBack color='primary' />
						</IconButton>
						{location.state.policy}
					</Typography>
					<br />
					<iframe
						style={{ scrollbarColor: 'auto' }}
						src={location.state.url}
						title='iframe'
						width='100%'
						height={`${document.body.scrollHeight}px`}
					/>
				</Box>
			</Container>
		</>
	);
};

export default PolicyPage;
