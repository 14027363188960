const getServiceDetail = (Order: any) => {
	if (Order.order_type === 'prepaid_fixed_price')
		return Order.active_details.services.map((service: any) => ({
			name: service.service_category.name,
			serviceDetails: service.service_option.name,
			price: service.cost ?? 0,
		}));
	if (Order.order_type === 'offer')
		return [
			{
				name: Order.offer.title,
				serviceDetails: Order.active_details.active_offer_option.title,
				price: Order?.active_details?.total_price ?? 0,
			},
		];
	if (Order.order_type === 'quotation')
		return Order.services.map((service: any, index: number) => ({
			name: service.service_category.name,
			serviceDetails: service.name,
			price: Order?.quotations[0]?.quote_detail[index]?.price ?? 0,
			id: service._id,
		}));

	if (Order.order_type === 'subscription')
		return Order.active_details.services.map((service: any) => ({
			name: service.service_category.name,
			serviceDetails: service.service_option.name,
			price: service.cost ?? 0,
		}));

	throw new Error('ervice Error');
};

export default getServiceDetail;
