/* eslint-disable react/jsx-props-no-spreading */
import {
	Grid,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FiberManualRecord } from '@material-ui/icons';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import EmptyList from 'components/EmptyList';
import i18n from 'i18n';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { io } from 'socket.io-client';
import { mutate } from 'swr';
import { useNewOrders, useWaitingOrders } from 'utils/orders';

const carLogoPath =
	'https://images.automate-app.com/app/automate_pro/car_emblem_mechanic_invert/';
const ENDPOINT = 'https://api.automate-app.com';

const useStyles = makeStyles({
	notificationIcon: {
		width: '20px',
		marginLeft: '3px',
		marginRight: '12px',
		verticalAlign: 'middle',
		objectFit: 'contain',
	},
	img: {
		width: '20px',
		marginLeft: '3px',
		marginRight: '12px',
		verticalAlign: 'middle',
		objectFit: 'contain',
	},
	tabs: {
		width: '100%',
		maxWidth: 'max-content',
		color: 'white',
	},
	card: {
		padding: '1%',
	},
	tableCell: {
		borderBottomColor: 'rgba(255, 255, 255, 0.15)',
		borderBottomWidth: '1px',
		borderBottomStyle: 'solid',
		fontSize: '16px',
		fontWeight: 300,
	},
	dateCell: {
		color: 'rgba(255, 255, 255, 0.65)',
		backgroundColor: 'black',
		padding: '6px',
		fontSize: '16px',
		fontWeight: 300,
	},
	noPadding: {
		padding: '0',
	},
});

export const ordersRow = (
	orders: any,
	shopId: string,
	classes: any,
	path: string
) => {
	const lang = i18n.t('Moment:lang');
	moment.locale(lang);
	const dateCreated = [] as string[];
	return orders.map((service: any) => {
		const date = moment(service.createdDate).format('MMM Do, dddd');
		const tableRow = [
			<TableRow hover key={service.orderId}>
				<TableCell className={classes.tableCell} component='th' scope='row'>
					<Link
						style={{
							textDecoration: 'none',
							color: 'white',
						}}
						to={{
							pathname: `${path}/${shopId}/${service.orderId}`,
						}}>
						<div style={{ flexDirection: 'row', display: 'flex' }}>
							{service.chatUnread === true ? (
								<div
									style={{
										marginLeft: '3px',
										marginRight: '12px',
										color: '#4aadff',
									}}>
									<FiberManualRecord />
								</div>
							) : (
								<img
									className={classes.img}
									src={carLogoPath + service.carLogo}
									alt='Car Logo'
								/>
							)}
							<div style={{ flex: 1, fontSize: 16, fontWeight: 300 }}>
								{service.car}
							</div>
							<div style={{ fontSize: 16, fontWeight: 700 }}>
								{service.licensePlate}
							</div>
						</div>
						<div
							style={{
								paddingLeft: '36px',
								paddingTop: '10px',
								fontSize: '20px',
							}}>
							{service.service}
						</div>
					</Link>
				</TableCell>
			</TableRow>,
		];

		if (dateCreated.indexOf(date) === -1) {
			tableRow.unshift(
				<TableRow key={`${service.orderId}_`}>
					<TableCell colSpan={3} className={classes.dateCell}>
						{date}
					</TableCell>
				</TableRow>
			);
			dateCreated.push(date);
		}
		return tableRow;
	});
};

interface NewOrderTabsProps {
	shopId: string;
}
const NewOrderTabs = ({ shopId }: NewOrderTabsProps) => {
	const { t } = useTranslation('Tabs');
	const classes = useStyles();
	const [value, setValue] = useState('one');
	const handleChange = (_event: any, newValue: string) => setValue(newValue);

	const newOrders = useNewOrders(shopId);
	const waitingConfirmationOrders = useWaitingOrders(shopId);

	useEffect(() => {
		const socket = io(ENDPOINT);

		socket.emit('MECHANIC_CONNECTED', {
			mechanic_id: localStorage.getItem('mechanicId'),
		});

		socket.on('MECHANIC.ORDER.STATUS', () => {
			// mutate is a re-fetch mechanism from SWR
			mutate(`fetchWaitingOrderWithIdOf${shopId}`);
			mutate(`fetchNewOrderWithIdOf${shopId}`);
			// console.log(data);
			socket.emit('MECHANIC_STATUS_UPDATE_ACKNOWLEDGEMENT', {
				mechanic_id: localStorage.getItem('mechanicId'),
				message: 'Mechanic Order updated',
			});
		});
		// socket.on('MECHANIC_CONNECTION_ESTABLISHED', (data) => {
		// 	console.log(data);
		// });

		// socket.on('disconnect', () => {
		// 	console.log('disconnect');
		// });
		// socket.on('reconnect_attempt', () => {
		// console.log(`reconnect_attempt ${data}`);
		// 	socket.io.opts.transports = ['polling', 'websocket'];
		// });
		// socket.on('reconnect', (data) => {
		// 	console.log(`reconnect ${data}`);
		// });

		// socket.on('reconnecting', (data) => {
		// 	console.log(`reconnecting ${data}`);
		// });

		// socket.on('reconnect_error', (data) => {
		// 	console.log(`reconnect_error ${data}`);
		// });

		// socket.on('reconnect_failed', (data) => {
		// 	console.log(`reconnect_failed ${data}`);
		// });
	}, []);

	const getNewOrders = () => ordersRow(newOrders, shopId, classes, '/order');

	const renderNewOrders = newOrders?.length ? (
		<Table aria-label='New Order'>
			<TableBody>{getNewOrders()}</TableBody>
		</Table>
	) : (
		<EmptyList />
	);

	const getWaitingConfirmationOrders = () =>
		ordersRow(
			waitingConfirmationOrders,
			shopId,
			classes,
			'/waiting-confirmation'
		);

	const renderWaitingOrders = waitingConfirmationOrders?.length ? (
		<Table aria-label='Waiting Comfirmation'>
			<TableBody>{getWaitingConfirmationOrders()}</TableBody>
		</Table>
	) : (
		<EmptyList />
	);

	return (
		<Grid container>
			<Grid item xs>
				<TabContext value={value}>
					<TabList
						onChange={handleChange}
						indicatorColor='primary'
						textColor='primary'
						variant='fullWidth'>
						<Tab label={t('tabNewRequests')} value='one' />
						<Tab label={t('tabSent')} value='two' />
					</TabList>
					<TabPanel className={classes.noPadding} value='one'>
						{renderNewOrders}
					</TabPanel>
					<TabPanel className={classes.noPadding} value='two'>
						{renderWaitingOrders}
					</TabPanel>
				</TabContext>
			</Grid>
		</Grid>
	);
};

export default NewOrderTabs;
