import BottomNav from './BottomNav.json';
import Tabs from './Tabs.json';
import Moment from './Moment.json';
import Menu from './Menu.json';
import Order from './Order.json';
import PickUpPin from './PickUpPin.json';
import Tag from './Tag.json';

const Chinese = { BottomNav, Tabs, Moment, Menu, Order, PickUpPin, Tag };
export default Chinese;
