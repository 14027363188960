import { Grid, IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { useStyles } from '../../theme/components';
import LanguageSwitcher from '../LanguageSwitcher';

const LanguagePage = () => {
	const classes = useStyles();
	return (
		<Grid container justifyContent='center'>
			<Grid item xs={4}>
				<IconButton component={Link} to='/menu' className={classes.yellow}>
					<ArrowBack />
				</IconButton>
				<span className={classes.yellow}>Menu</span>
				<LanguageSwitcher />
			</Grid>
		</Grid>
	);
};

export default LanguagePage;
