import api from 'api';
import useSWR from 'swr';

const useServices = () => {
	const { data, error } = useSWR('useServices', () =>
		api.services.getServices(localStorage.automateJWT)
	);

	return {
		services: data,
		error,
	};
};
export default useServices;
