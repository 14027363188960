import {
	Box,
	Button,
	Checkbox,
	CheckboxProps,
	Grid,
	IconButton,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Tabs,
} from '@material-ui/core';
import {
	ArrowBack,
	ArrowForward,
	FiberManualRecord,
	LocalOffer,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import automateLogo from 'images/logo_automate_alt_yellow.png';
import moment from 'moment';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import 'sendbird-uikit/dist/index.css';
import { useStyles } from 'theme/orderPage';
import { completeRepairing, useViewOrder } from 'utils/orders';
import CustomDialog from '../../CustomDialog';
import ChatRoom from '../chatroom/ChatRoom';
import getOrderDetail from './panelActions/getOrderDetail';
import getServiceDetail from './panelActions/getServiceDetail';

const carLogoPath =
	'https://images.automate-app.com/app/automate_pro/car_emblem_mechanic_invert/';

interface TabPanelProps {
	children: ReactNode;
	value: Number;
	index: Number;
}

const TabPanel = (props: TabPanelProps) => {
	const { children, value, index } = props;
	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`order-tabpanel-${index}`}
			aria-labelledby={`check-order-tab-${index}`}>
			{value === index && children}
		</div>
	);
};

const GreenCheckbox = withStyles({
	root: {
		color: '#44cc00',
		'&$checked': {
			color: '#44cc00',
		},
	},
	// eslint-disable-next-line react/jsx-props-no-spreading
})((props: CheckboxProps) => <Checkbox color='default' {...props} />);

const CheckOrderItemPage = () => {
	const { shopId, orderId } = useParams<{ shopId: string; orderId: string }>();
	const [selected, setSelected] = useState([] as any);
	const [value, setValue] = useState(0);
	const [Error, setError] = useState(false);
	const classes = useStyles();
	const history = useHistory();
	const { t } = useTranslation(['Order', 'Moment']);
	moment.locale(t('Moment:lang'));

	const viewOrder = useViewOrder(orderId);
	const order = (viewOrder && getOrderDetail(viewOrder)) || [];
	const services = (viewOrder && getServiceDetail(viewOrder)) || [];

	const handleChange = (_event: any, newValue: number) => setValue(newValue);

	const handleClick = (_event: any, name: string) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [] as any;

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
	};

	const handleDone = () =>
		completeRepairing(orderId, shopId)
			.then(() => {
				const pathname = '/job-done';
				const state = {
					jobTitle: '柯打完成',
					jobDetails:
						'車主會被通知可以取車，請以短信聯絡車主預約取車日期，多謝。',
					nextPath: '/processing-order',
				};
				history.replace(pathname, state);
			})
			.catch(() => setError(true));

	const getServices = () =>
		services.map((service: any, index: number) => {
			const isItemSelected = selected.indexOf(service.name) !== -1;
			return (
				<TableRow
					hover
					onClick={(event) => handleClick(event, service.name)}
					// eslint-disable-next-line react/no-array-index-key
					key={index}
					role='checkbox'>
					<TableCell
						padding='checkbox'
						className={classes.tableCell}
						style={{ textAlign: 'center', verticalAlign: 'middle' }}>
						<GreenCheckbox checked={isItemSelected} />
					</TableCell>
					<TableCell colSpan={3} className={classes.tableCell}>
						<div style={{ float: 'right' }}>
							{order.orderType !== 'subscription' && (
								<span className={classes.price}> ${service.price}</span>
							)}
						</div>
						<div className={classes.serviceName}>{service.name}</div>
						<br />
						<div className={classes.serviceDetails}>
							{service.serviceDetails}
						</div>
					</TableCell>
				</TableRow>
			);
		});

	const getOrderType = () => {
		if (order.orderType === 'prepaid_fixed_price')
			return (
				<TableRow key='prepaid'>
					<TableCell className={classes.orderType} colSpan={3}>
						<>
							<img className={classes.img} src={automateLogo} alt='Logo' />
							{t('Fixed Price Service')}
						</>
					</TableCell>
				</TableRow>
			);

		if (order.orderType === 'offer')
			return (
				<TableRow key='offer'>
					<TableCell className={classes.orderType} colSpan={3}>
						<Box display='flex' alignItems='center'>
							<LocalOffer />
							{t('Offer')}
						</Box>
					</TableCell>
				</TableRow>
			);
		return null;
	};

	return (
		<Grid container justifyContent='center'>
			<Grid item xs={12}>
				<IconButton onClick={history.goBack} className={classes.yellow}>
					<ArrowBack />
				</IconButton>
				<span className={classes.yellow}>{t('Order Detail')}</span>
				<br />
				<div className={classes.car}>
					{order.carLogo && (
						<img
							className={classes.img}
							src={carLogoPath + order.carLogo}
							alt='Car Logo'
						/>
					)}
					<div className={classes.carModel}>{order.car}</div>
					<div className={classes.carLicensePlate}>{order.licensePlate}</div>
					<div className={classes.carOwner}>{order.name}</div>
				</div>
				<Tabs
					className={classes.messageTab}
					value={value}
					onChange={handleChange}
					indicatorColor='primary'
					textColor='primary'
					variant='fullWidth'>
					<Tab className={classes.messageTabs} label={t('Detail')} />
					{order.chatUnread === true ? (
						<Tab
							className={classes.messageTabs}
							label={
								<>
									<FiberManualRecord
										style={{ color: '#4aadff' }}
										viewBox='0 -5 30 30'
									/>
									{t('Message')}
								</>
							}
						/>
					) : (
						<Tab className={classes.messageTabs} label={t('Message')} />
					)}
				</Tabs>
				<TabPanel value={value} index={0}>
					<div className={classes.bookedDate}>
						<div className={classes.preferDate}>
							{t('Completed on')}
							{moment(order.preferDate).format('MMM Do, dddd')}
						</div>
					</div>
					<Table className={classes.table} aria-label='New Order'>
						<TableBody>
							{getOrderType()}
							{getServices()}
						</TableBody>
					</Table>
					{selected.length === services.length ? (
						<div className={classes.acceptButton}>
							<p style={{ color: '#FFE700', fontSize: '14px' }}>
								{t('Check Order Item Message')}
							</p>
							{Error && (
								<CustomDialog
									title='Error'
									text='An error has occured'
									dispatch={setError}
									button='OK'
								/>
							)}
							<Button
								onClick={handleDone}
								className={classes.button3}
								color='primary'
								variant='contained'
								size='large'
								endIcon={<ArrowForward />}
								fullWidth>
								{t('Notify Owner')}
							</Button>
						</div>
					) : (
						<br />
					)}
				</TabPanel>
				<TabPanel value={value} index={1}>
					<ChatRoom chatroomId={order.chatroomId} />
				</TabPanel>
			</Grid>
		</Grid>
	);
};

export default CheckOrderItemPage;
