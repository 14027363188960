const getOrderDetail = (Order: any): any => ({
	orderId: Order._id,
	quotationId: Order?.active_quotation?._id,
	carOwnerId: Order.order_by._id,
	chatroomId: Order?.chatroom?.channel_id,
	chatUnread: Order?.chatroom?.mechanic_unread,
	name: `${Order.order_by.firstname}  ${Order.order_by.lastname} `,
	car: `${Order.car.carmodel.manufacturer} ${Order.car.carmodel.model} ${Order.car.carmodel.trim} (${Order.car.carmodel.year})`,
	carLogo: Order.car.carmodel.logo.replace('car_logo', 'car_emblem'),
	licensePlate: Order.car.license_plate,
	preferDate: Order.prefer_date,
	orderType: Order.order_type,
	totalPrice: Order?.active_details?.total_price ?? 0,
});

export default getOrderDetail;
