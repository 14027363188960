import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import English from 'locales/en/translationEN';
import Chinese from 'locales/zh/translationZH';

i18n.use(initReactI18next).init({
	// we init with resources
	resources: {
		en: English,
		zh: Chinese,
	},
	fallbackLng: 'en',
	lng: 'en',
	debug: true,

	// Namespaces
	ns: Object.keys(English),

	keySeparator: false, // we use content as keys

	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
