/* eslint-disable react/jsx-props-no-spreading */
import { ReactNode, useState } from 'react';
import moment from 'moment';
import {
	Box,
	Button,
	Chip,
	Grid,
	IconButton,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Tabs,
} from '@material-ui/core';
import {
	ArrowBack,
	ArrowForward,
	FiberManualRecord,
	LocalOffer,
} from '@material-ui/icons';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'theme/orderPage';
import { useViewOrder } from 'utils/orders';
import automateLogo from 'images/logo_automate_alt_yellow.png';
import ChatRoom from '../chatroom/ChatRoom';
import getServiceDetail from './panelActions/getServiceDetail';
import getOrderDetail from './panelActions/getOrderDetail';

const carLogoPath =
	'https://images.automate-app.com/app/automate_pro/car_emblem_mechanic_invert/';

export interface TabPanelProps {
	children: ReactNode;
	value: Number;
	index: Number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`order-tabpanel-${index}`}
			aria-labelledby={`check-order-tab-${index}`}>
			{value === index && children}
		</div>
	);
}

const CompleteOrderPage = () => {
	const { shopId, orderId } = useParams<{ shopId: string; orderId: string }>();
	const [value, setValue] = useState(0);
	const handleChange = (_event: any, newValue: number) => setValue(newValue);
	const classes = useStyles();
	const history = useHistory();
	const viewOrder = useViewOrder(orderId);
	const order = (viewOrder && getOrderDetail(viewOrder)) || [];
	const services = (viewOrder && getServiceDetail(viewOrder)) || [];
	const { t } = useTranslation(['Order', 'Moment']);
	moment.locale(t('Moment:lang'));

	const getServices = () =>
		services.map((service: any) => (
			<TableRow key={service.name}>
				<TableCell colSpan={3} className={classes.tableCell}>
					<div style={{ float: 'right' }}>
						{order.orderType === 'subscription' ? (
							<Chip size='small' label={order.orderType} />
						) : (
							<span className={classes.price}> ${service.price}</span>
						)}
					</div>
					<div className={classes.serviceName}>{service.name}</div>
					{service.serviceDetails !== service.name && (
						<div className={classes.serviceDetails}>
							{service.serviceDetails}
						</div>
					)}
				</TableCell>
			</TableRow>
		));

	const getOrderType = () => {
		if (order.orderType === 'prepaid_fixed_price')
			return (
				<TableRow key='prepaid'>
					<TableCell className={classes.orderType} colSpan={3}>
						<>
							<img className={classes.img} src={automateLogo} alt='Logo' />
							{t('Fixed Price Service')}
						</>
					</TableCell>
				</TableRow>
			);

		if (order.orderType === 'offer')
			return (
				<TableRow key='offer'>
					<TableCell className={classes.orderType} colSpan={3}>
						<Box display='flex' alignItems='center'>
							<LocalOffer />
							{t('Offer')}
						</Box>
					</TableCell>
				</TableRow>
			);
		return null;
	};
	const getQuotationTotalPrice = (priceNotZero: boolean) =>
		priceNotZero ? (
			<>
				<TableRow key='QTP1'>
					<TableCell className={classes.table} colSpan={3}>
						<div className={classes.totalPrice} style={{ float: 'right' }}>
							${Number(order.totalPrice).toFixed(2)}
						</div>
						<div className={classes.priceLabel}>{t('Total')}</div>
					</TableCell>
				</TableRow>

				<TableRow key='QTP2'>
					<TableCell className={classes.table} colSpan={3}>
						<div className={classes.totalPrice} style={{ float: 'right' }}>
							-${Number(order.totalPrice * 0.05).toFixed(2)}
						</div>
						<div className={classes.priceLabel}>{t('Fee')}(5%)</div>
					</TableCell>
				</TableRow>

				<TableRow key='QTP3'>
					<TableCell className={classes.yellow} colSpan={3}>
						<div
							className={`${classes.totalPrice} ${classes.yellow}`}
							style={{ float: 'right' }}>
							${Number(order.totalPrice * 0.95).toFixed(2)}
						</div>
						<div className={classes.priceLabel}>{t('Net Income')}</div>
					</TableCell>
				</TableRow>
			</>
		) : null;

	return (
		<Grid container justifyContent='center'>
			<Grid item xs={12}>
				<IconButton onClick={history.goBack} className={classes.yellow}>
					<ArrowBack />
				</IconButton>
				<span className={classes.yellow}>{t('Order Detail')}</span>
				<br />
				<div className={classes.car}>
					{order.carLogo && (
						<img
							className={classes.img}
							src={carLogoPath + order.carLogo}
							alt='Car Logo'
						/>
					)}
					<div className={classes.carModel}>{order.car}</div>
					<div className={classes.carLicensePlate}>{order.licensePlate}</div>
					<div className={classes.carOwner}>{order.name}</div>
				</div>
				<Tabs
					className={classes.messageTab}
					value={value}
					onChange={handleChange}
					indicatorColor='primary'
					textColor='primary'
					variant='fullWidth'>
					<Tab className={classes.messageTabs} label={t('Detail')} />
					{order.chatUnread === true ? (
						<Tab
							className={classes.messageTabs}
							label={
								<>
									<FiberManualRecord
										style={{ color: '#4aadff' }}
										viewBox='0 -5 30 30'
									/>
									{t('Message')}
								</>
							}
						/>
					) : (
						<Tab className={classes.messageTabs} label={t('Message')} />
					)}
				</Tabs>
				<TabPanel value={value} index={0}>
					<div className={classes.bookedDate}>
						<div className={classes.preferDate}>
							{t('Completed on')}
							{moment(order.preferDate).format('MMM Do')}
						</div>
						<div className={classes.acceptButton2}>
							<Link
								replace
								to={`/pick-up/${shopId}/${orderId}`}
								style={{ textDecoration: 'none' }}>
								<Button
									className={classes.button3}
									color='primary'
									variant='contained'
									size='large'
									endIcon={<ArrowForward />}
									fullWidth>
									{t('Confirm Job Done')}
								</Button>
							</Link>
						</div>
					</div>
					<Table className={classes.table} aria-label='New Order'>
						<TableBody>
							{getOrderType()}
							{getServices()}
							{getQuotationTotalPrice(order.totalPrice)}
						</TableBody>
					</Table>
				</TabPanel>
				<TabPanel value={value} index={1}>
					<ChatRoom chatroomId={order.chatroomId} />
				</TabPanel>
			</Grid>
		</Grid>
	);
};

export default CompleteOrderPage;
