/* eslint-disable react/jsx-no-duplicate-props */
import {
	Box,
	Button,
	Card,
	CardContent,
	CardMedia,
	InputAdornment,
	LinearProgress,
	makeStyles,
	Paper,
} from '@material-ui/core';
import { AccountCircle, Lock } from '@material-ui/icons';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import homePageLogo from 'images/logo_automate_black.png';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { login } from 'utils/auth';
import { useSetRecoilState } from 'recoil';
import RecoilStates from 'RecoilStates';

const useStyles = makeStyles({
	input: {
		'&:-webkit-autofill': {
			WebkitBoxShadow: '0 0 0 1000px white inset',
		},
	},
	marginTop: {
		marginTop: '30px',
	},
	image: {
		width: '85%',
		margin: '0 auto',
		position: 'relative',
		top: '-65px',
		marginBottom: '-65px',
		backgroundColor: '#FFA500',
		padding: '15px',
		borderRadius: '3px',
	},
	card: { overflow: 'visible' },
});

interface Values {
	email: string;
	password: string;
}

const validationSchema = yup.object({
	email: yup
		.string()
		.email('Enter a valid email')
		.required('Email is required'),
	password: yup.string().required('Password is required'),
});

const LoginForm = () => {
	const classes = useStyles();
	const history = useHistory();
	const setLoggedIn = useSetRecoilState(RecoilStates.login);
	const submitHandler = (values: Values) =>
		login(values).then(() => {
			setLoggedIn(true);
			history.push('/new-order');
		});

	return (
		<Card className={classes.card}>
			<Box p='30px'>
				<Paper className={classes.image}>
					<CardMedia
						component='img'
						image={homePageLogo}
						title='Automate logo black'
					/>
				</Paper>
				<CardContent>
					<Formik
						initialValues={{ email: '', password: '' }}
						validationSchema={validationSchema}
						onSubmit={submitHandler}>
						{({ submitForm, isSubmitting }) => (
							<Form>
								<Field
									placeholder='電子郵件地址'
									className={classes.marginTop}
									component={TextField}
									inputProps={{ className: classes.input }}
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>
												<AccountCircle />
											</InputAdornment>
										),
									}}
									fullWidth
									variant='outlined'
									name='email'
									type='email'
									label='Email'
								/>
								<br />
								<Field
									placeholder='密碼'
									className={classes.marginTop}
									component={TextField}
									inputProps={{ className: classes.input }}
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>
												<Lock />
											</InputAdornment>
										),
									}}
									my='20px'
									fullWidth
									variant='outlined'
									type='password'
									label='Password'
									name='password'
								/>
								<br />
								<Button
									className={classes.marginTop}
									fullWidth
									variant='contained'
									color='primary'
									disabled={isSubmitting}
									onClick={submitForm}>
									Submit
								</Button>
								{isSubmitting && <LinearProgress color='primary' />}
							</Form>
						)}
					</Formik>
				</CardContent>
			</Box>
		</Card>
	);
};

export default LoginForm;
