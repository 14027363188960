import useSWR from 'swr';
import _ from 'lodash';
import api from '../api';

const jwt = localStorage.automateJWT;

export const getService = (service: any) => {
	if (service.order_type === 'prepaid_fixed_price')
		return service.active_details.services[0].service_category.name;
	if (service.order_type === 'quotation')
		return service.services[0].service_category.name;
	if (service.order_type === 'offer') return service.offer.title;
	if (service.order_type === 'subscription')
		return service.active_details.services[0].service_category.name;
	return '';
};

export const acceptFixedPriceOrder = (orderId: string, shopId: string) =>
	api.orders.acceptFixedPriceOrder(localStorage.automateJWT, orderId, shopId);

export const viewOrder = (orderId: string) =>
	api.orders
		.viewOrder(localStorage.automateJWT, orderId)
		.then((order) => order);

export const useViewOrder = (orderId: string) => {
	const { data } = useSWR(`viewOrderWithOrderIdOf${orderId}`, () =>
		api.orders.viewOrder(localStorage.automateJWT, orderId)
	);
	return data;
};

export const confirmPickUpPin = (
	orderId: string,
	shopId: string,
	pickUpPin: string
) =>
	api.orders
		.confirmPickUpPin(localStorage.automateJWT, orderId, shopId, pickUpPin)
		.then((data) => data);

export const completeRepairing = (orderId: string, shopId: string) =>
	api.orders.completeRepairing(localStorage.automateJWT, orderId, shopId);

export const acceptOrder = (orderId: string, shopId: string) =>
	api.orders.acceptOrder(localStorage.automateJWT, orderId, shopId);

export const skipOrder = (orderId: string) =>
	api.orders.skipOrder(localStorage.automateJWT, orderId);

export const discardQuote = (orderId: string) =>
	api.orders.discardQuotedOrder(localStorage.automateJWT, orderId);

export const createQuote = (
	orderId: string,
	shopId: string,
	quotations: any,
	services: any
) =>
	api.orders.createQuote(
		localStorage.automateJWT,
		orderId,
		shopId,
		quotations,
		services
	);

export const patchQuote = (quotationId: string, data: object) =>
	api.orders.reviseQuotedOrder(localStorage.automateJWT, quotationId, data);

export const useOrderHistory = (shopId: string) => {
	const { data } = useSWR(`fetchOrderHistoryWithIdOf${shopId}`, () =>
		api.orders.fetchOrderHistory(localStorage.automateJWT, shopId)
	);

	const orders = {
		fixedPriceList: [] as any,
		quotationList: [] as any,
		offerList: [] as any,
	};

	if (!data) return orders;
	data.data.forEach((service: any) => {
		switch (service.order_type) {
			case 'prepaid_fixed_price':
				return orders.fixedPriceList.push({
					orderId: service._id,
					licensePlate: service.car.license_plate,
					car: `${service.car.carmodel.manufacturer} ${service.car.carmodel.model} ${service.car.carmodel.year}`,
					carLogo: service.car.carmodel.logo.replace('car_logo', 'car_emblem'),
					service: service.active_details.services[0].service_category.name,
					createdDate: service.created_at,
					orderType: service.order_type,
					price: service.active_details.total_price,
					driverName: service.order_by.firstname,
				});
			case 'offer':
				return orders.offerList.push({
					orderId: service._id,
					licensePlate: service.car.license_plate,
					car: `${service.car.carmodel.manufacturer} ${service.car.carmodel.model} ${service.car.carmodel.year}`,
					carLogo: service.car.carmodel.logo.replace('car_logo', 'car_emblem'),
					service: service.offer.title,
					createdDate: service.created_at,
					orderType: service.order_type,
					price: service.active_details.total_price,
				});

			case 'quotation':
				return orders.quotationList.push({
					orderId: service._id,
					licensePlate: service.car.license_plate,
					car: `${service.car.carmodel.manufacturer} ${service.car.carmodel.model} ${service.car.carmodel.year}`,
					carLogo: service.car.carmodel.logo.replace('car_logo', 'car_emblem'),
					service: service.services[0].service_category.name,
					createdDate: service.created_at,
					orderType: service.order_type,
					price: service.active_details.total_price,
				});
			default:
				return null;
		}
	});
	return orders;
};

export const useNewOrders = (shopId: string) => {
	const { data } = useSWR(`fetchNewOrderWithIdOf${shopId}`, () =>
		api.orders.fetchNewOrders(localStorage.automateJWT, shopId)
	);
	const result =
		data &&
		data.data.map((service: any) => ({
			orderId: service._id,
			licensePlate: service.car.license_plate,
			car: `${service.car.carmodel.manufacturer} ${service.car.carmodel.model} ${service.car.carmodel.year}`,
			carLogo: service.car.carmodel.logo.replace('car_logo', 'car_emblem'),
			service: getService(service),
			createdDate: service.created_at,
		}));
	return result || [];
};

export const useProcessingOrders = (shopId: string) => {
	const { data } = useSWR(`fetchProcessingOrderWithIdOf${shopId}`, () =>
		api.orders.fetchProcessingOrders(localStorage.automateJWT, shopId)
	);
	const result =
		data &&
		data.data.map((service: any) => ({
			orderId: service._id,
			chatUnread: service.chatroom?.mechanic_unread,
			licensePlate: service.car.license_plate,
			car: `${service.car.carmodel.manufacturer} ${service.car.carmodel.model} ${service.car.carmodel.year}`,
			carLogo: service.car.carmodel.logo.replace('car_logo', 'car_emblem'),
			service: getService(service),
		}));
	return result || [];
};

export const useBookedOrders = (shopId: string) => {
	const { data } = useSWR(`fetchBookedOrderWithIdOf${shopId}`, () =>
		api.orders.fetchBookedOrders(localStorage.automateJWT, shopId)
	);
	const result =
		data &&
		data.data.map((service: any) => ({
			orderId: service._id,
			chatUnread: service.chatroom?.mechanic_unread,
			licensePlate: service.car.license_plate,
			car: `${service.car.carmodel.manufacturer} ${service.car.carmodel.model} ${service.car.carmodel.year}`,
			carLogo: service.car.carmodel.logo.replace('car_logo', 'car_emblem'),
			service: getService(service),
		}));

	return result || [];
};

export const useCompletedOrders = (shopId: string) => {
	const { data } = useSWR(`fetchCompletedOrderWithIdOf${shopId}`, () =>
		api.orders.fetchCompletedOrders(localStorage.automateJWT, shopId)
	);
	if (data)
		return data.data.map((service: any) => ({
			orderId: service._id,
			chatUnread: service.chatroom?.mechanic_unread,
			licensePlate: service.car.license_plate,
			car: `${service.car.carmodel.manufacturer} ${service.car.carmodel.model} ${service.car.carmodel.year}`,
			carLogo: service.car.carmodel.logo.replace('car_logo', 'car_emblem'),
			service: getService(service),
		}));
	return [];
};

export const useWaitingOrders = (shopId: string) => {
	const { data } = useSWR(`fetchWaitingOrderWithIdOf${shopId}`, () =>
		api.orders.fetchWaitingConfirmationOrders(localStorage.automateJWT, shopId)
	);
	const result =
		data &&
		data.data
			.filter((service: any) => service.status === 'waiting_confirmation')
			.map((service: any) => ({
				orderId: service.linked_order._id,
				licensePlate: service?.linked_order?.car?.license_plate,
				car: `${service?.linked_order?.car?.carmodel?.manufacturer} ${service?.linked_order?.car?.carmodel?.model} ${service?.linked_order?.car?.carmodel?.year}`,
				carLogo: service?.linked_order?.car?.carmodel?.logo?.replace(
					'car_logo',
					'car_emblem'
				),
				service: service?.quote_detail[0]?.name,
				createdDate: service?.linked_order?.created_at,
			}));
	return result || [];
};

export interface Shop {
	shopName: string;
	shopId: string;
}

export const useShopList = () => {
	const { data } = useSWR('useShopList', () =>
		api.shop.fetchShopList(localStorage.automateJWT)
	);
	if (data)
		return {
			shopList: data.shops
				.map(
					(item: any): Shop => ({
						shopName: item.name,
						shopId: item._id,
					})
				)
				.sort(({ shopId: shopIdA }: Shop, { shopId: shopIdB }: Shop) =>
					shopIdA > shopIdB ? 1 : -1
				) as Shop[],
		};
	return { shopList: null };
};

export const useViewShop = (shopId: string) => {
	const { data } = useSWR(`useViewShopWithIdOf${shopId}`, () =>
		api.shop.viewShop(jwt, shopId)
	);
	return {
		shopName: data?.name ?? '',
		rate: data ? _.round(data.avg_overall, 1) : 0,
	};
};
