import axios from 'axios';
import decode from 'jwt-decode';
import _ from 'lodash';

const hostDevelopment = 'https://api.automate-app.com';

// production api url
// const hostProduction = 'https://api.staging.automate-app.com';

function getCurrentTime() {
	const dateTime = +new Date();
	return Math.floor(dateTime / 1000);
}

function getAccessTokens(token: string): string {
	try {
		const expiryTime = decode<any>(token).exp as number;
		if (expiryTime < getCurrentTime()) {
			axios
				.post(`${hostDevelopment}/api/v2/mechanics/auth/refresh`, {
					refresh_token: localStorage.refreshToken,
				})
				.then((res) => {
					localStorage.automateJWT = res.data.access_token;
					localStorage.refreshToken = res.data.refresh_token;
				})
				.then(() => setTimeout(() => window.location.reload(), 200));
		}
	} catch (e) {
		// console.error(e);
	}
	return localStorage.automateJWT;
}

export interface Credentials {
	email: string;
	password: string;
}

export default {
	user: {
		login: (credentials: Credentials) =>
			axios
				.post(`${hostDevelopment}/api/v2/mechanics/auth/login`, credentials)
				.then((res) => res.data),
		// changePassword: (newPassword, newPasswordConfirmation, currentPassword) =>
		// 	axios
		// 		.post(
		// 			`${hostDevelopment}/api/v2/mechanics/password/change`,
		// 			{
		// 				current_password: currentPassword,
		// 				new_password: newPassword,
		// 				new_password_confirmation: newPasswordConfirmation,
		// 			},
		// 			{
		// 				params: {
		// 					'x-access-token': getAccessTokens(localStorage.automateJWT),
		// 				},
		// 			}
		// 		)
		// 		.then((res) => res.data),
		updateProfile: (json: FormData) =>
			axios
				.patch(`${hostDevelopment}/api/v2/mechanics/profile`, json, {
					params: {
						'x-access-token': getAccessTokens(localStorage.automateJWT),
						// this endpoint uses form-data
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((res) => res),
		// signup: (user) =>
		// 	axios
		// 		.post(`${hostDevelopment}/api/v2/mechanics/auth/register`, user)
		// 		.then((res) => res.data.user),
		// confirm: (token) =>
		// 	axios
		// 		.post('/api/auth/confirmation', { token })
		// 		.then((res) => res.data.user),
		// resetPasswordRequest: (email) =>
		// 	axios.post('/api/auth/reset_password_request', { email }),
		// validateToken: (token) => axios.post('/api/auth/validate_token', { token }),
		// resetPassword: (data) => axios.post('/api/auth/reset_password', { data }),
		getProfile: (token: string) =>
			axios
				.get(`${hostDevelopment}/api/v2/mechanics/profile`, {
					params: {
						'x-access-token': getAccessTokens(token),
					},
				})
				.then((res) => res.data.data),
	},
	shop: {
		fetchShopList: (token: string) =>
			axios
				.get(`${hostDevelopment}/api/v2/mechanics/profile`, {
					params: {
						'x-access-token': getAccessTokens(token),
					},
				})
				.then((res) => res.data.data),
		viewShop: (token: string, shopId: string) =>
			axios
				.get(`${hostDevelopment}/api/v2/mechanics/shops/${shopId}`, {
					params: {
						'x-access-token': getAccessTokens(token),
					},
				})
				.then((res) => res.data.data),
	},
	orders: {
		viewOrder: (token: string, orderId: string) =>
			axios
				.get(`${hostDevelopment}/api/v2/mechanics/orders/${orderId}`, {
					params: {
						'x-access-token': getAccessTokens(token),
					},
				})
				.then((res) => res.data.data),
		confirmPickUpPin: (
			token: string,
			orderId: string,
			shopId: string,
			pickUpPin: string
		) =>
			axios
				.patch(
					`${hostDevelopment}/api/v2/mechanics/orders/${orderId}/acknowledge`,
					{ on_behalf_shop: shopId, pickup_pin: pickUpPin },
					{
						params: {
							'x-access-token': getAccessTokens(token),
						},
					}
				)
				.then((res) => res),
		completeRepairing: (token: string, orderId: string, shopId: string) =>
			axios.patch(
				`${hostDevelopment}/api/v2/mechanics/orders/${orderId}/ready_for_pickup`,
				{ on_behalf_shop: shopId },
				{
					params: {
						'x-access-token': getAccessTokens(token),
					},
				}
			),
		acceptOrder: (token: string, orderId: string, shopId: string) =>
			axios.patch(
				`${hostDevelopment}/api/v2/mechanics/orders/${orderId}/offer/accept`,
				{ on_behalf_shop: shopId },
				{
					params: {
						'x-access-token': getAccessTokens(token),
					},
				}
			),
		acceptFixedPriceOrder: (token: string, orderId: string, shopId: string) =>
			axios.patch(
				`${hostDevelopment}/api/v2/mechanics/orders/${orderId}/prepaid_fixed_price/accept`,
				{ on_behalf_shop: shopId },
				{
					params: { 'x-access-token': getAccessTokens(token) },
				}
			),

		createQuote: (
			token: string,
			orderId: string,
			shopId: string,
			quotations: any,
			services: any
		) => {
			const zip = _.zipWith(
				quotations,
				services,
				(quotation, { id }: { id: string }) => ({
					price: quotation,
					service_id: id,
				})
			);
			return axios.post(
				`${hostDevelopment}/api/v2/mechanics/quotations`,
				{
					order_id: orderId,
					quotation: zip,
					on_behalf_shop: shopId,
				},
				{ params: { 'x-access-token': getAccessTokens(token) } }
			);
		},
		skipOrder: (token: string, orderId: string) =>
			axios.patch(
				`${hostDevelopment}/api/v2/mechanics/orders/${orderId}/ignore`,
				{},
				{
					params: { 'x-access-token': getAccessTokens(token) },
				}
			),
		discardQuotedOrder: (token: string, orderId: string) =>
			axios.patch(
				`${hostDevelopment}/api/v2/mechanics/orders/${orderId}/discard`,
				{},
				{
					params: { 'x-access-token': getAccessTokens(token) },
				}
			),
		reviseQuotedOrder: async (
			token: string,
			quotationId: string,
			data: object
		) => {
			const fd = new FormData();
			fd.append('data', JSON.stringify(data));
			// console.log(Object.fromEntries(fd));

			await axios.patch(
				`${hostDevelopment}/api/v2/mechanics/quotations/${quotationId}/standard`,
				fd,
				{
					params: {
						'x-access-token': getAccessTokens(token),
						'Content-Type': 'multipart/form-data',
					},
				}
			);
			// .then((res) => console.log(res))
			// .catch((err) => console.log(err.response));
		},
		fetchOrderHistory: (token: string, shopId: string) =>
			axios
				.get(`${hostDevelopment}/api/v2/mechanics/orders`, {
					params: {
						status: 'completed',
						on_behalf_shop: shopId,
						'x-access-token': getAccessTokens(token),
					},
				})
				.then((res) => res.data),
		fetchNewOrders: (token: string, shopId: string) =>
			axios
				.get(`${hostDevelopment}/api/v2/mechanics/orders`, {
					params: {
						status: 'new',
						on_behalf_shop: shopId,
						'x-access-token': getAccessTokens(token),
					},
				})
				.then((res) => res.data),
		fetchProcessingOrders: (token: string, shopId: string) =>
			axios
				.get(`${hostDevelopment}/api/v2/mechanics/orders`, {
					params: {
						status: 'in_progress',
						on_behalf_shop: shopId,
						'x-access-token': getAccessTokens(token),
					},
				})
				.then((res) => res.data),
		fetchBookedOrders: (token: string, shopId: string) =>
			axios
				.get(`${hostDevelopment}/api/v2/mechanics/orders`, {
					params: {
						status: 'scheduled',
						on_behalf_shop: shopId,
						'x-access-token': getAccessTokens(token),
					},
				})
				.then((res) => res.data),
		fetchCompletedOrders: (token: string, shopId: string) =>
			axios
				.get(`${hostDevelopment}/api/v2/mechanics/orders`, {
					params: {
						status: 'ready_to_pickup',
						on_behalf_shop: shopId,
						'x-access-token': getAccessTokens(token),
					},
				})
				.then((res) => res.data),
		fetchWaitingConfirmationOrders: (token: string, shopId: string) =>
			axios
				.get(`${hostDevelopment}/api/v2/mechanics/quotations`, {
					params: {
						on_behalf_shop: shopId,
						'x-access-token': getAccessTokens(token),
					},
				})
				.then((res) => res.data),
		fetchWaitingQuotationOrders: (token: string) =>
			axios
				.get(`${hostDevelopment}/api/v2/mechanics/orders`, {
					params: {
						status: 'waiting_quotation',
						'x-access-token': getAccessTokens(token),
					},
				})
				.then((res) => res.data),
		fetchAll: () => axios.get('/api/orders').then((res) => res.data.orders),
		create: (order: any) =>
			axios.post('/api/orders', { order }).then((res) => res.data.orders),
	},
	chatroom: {
		readMessage: (chatroomId: string, token: string) =>
			axios
				.patch(
					`${hostDevelopment}/api/v2//mechanics/chatrooms/${chatroomId}/read`,
					{},
					{
						params: {
							'x-access-token': getAccessTokens(token),
						},
					}
				)
				.then((res) => res),
		getOrderById: (orderId: string) =>
			axios
				.get(`${hostDevelopment}/api/v2/mechanics/orders/${orderId}`)
				.then((res) => res),
		sendNotificationToken: (token: string) =>
			axios.patch(
				`${hostDevelopment}/api/v2//mechanics/web_token`,
				{
					web_notification_token: token,
				},
				{
					params: {
						'x-access-token': getAccessTokens(localStorage.automateJWT),
					},
				}
			),
	},
	services: {
		getServices: (token: string) =>
			axios
				.get(`${hostDevelopment}/api/v2/mechanics/services/`, {
					params: {
						'x-access-token': getAccessTokens(token),
					},
				})
				.then((res) => res.data.data),
	},
};
