import { Grid, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowRight } from '@material-ui/icons';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import RecoilStates from 'RecoilStates';
import { useShopList } from 'utils/orders';
import MenuTabs from './MenuTabs';
import NewOrderTabs from './NewOrderTabs';
import OrderHistoryTabs from './OrderHistoryTabs';
import ProcessingOrderTabs from './ProcessingOrderTabs';

const useStyles = makeStyles({
	tabLabel: {
		Width: 'fit-content',
		color: 'rgb(255,255,255,0.5)',
		textAlign: 'left',
		'& .MuiTab-wrapper': {
			justifyContent: 'left',
		},
	},
	root: {
		flexGrow: 1,
		display: 'flex',
	},
	tabs: {
		'& .MuiTabs-indicator': {
			backgroundColor: 'transparent',
		},
		minWidth: 'fit-content',
	},
	tabsPanel: {
		width: '100%',
		color: 'white',
		padding: '0',
	},
});

const ShopListTabs = () => {
	const { shopList } = useShopList();
	const [shopIndex, setShopIndex] = useRecoilState(RecoilStates.shopIndex);
	const classes = useStyles();
	const handleChange = (_foo: any, newValue: string) => setShopIndex(newValue);

	// store shopIndex to localStorage when unmount
	useEffect(() => () => localStorage.setItem('shopIndex', shopIndex), []);

	const getTabListItems = () => {
		if (!shopList) return null;
		return shopList.map((name, index) => (
			<Tab
				key={name.shopName}
				className={classes.tabLabel}
				icon={<ArrowRight fontSize='large' />}
				label={name.shopName}
				value={index.toString()}
			/>
		));
	};

	const getTabPanels = () => {
		if (!shopList) return null;
		return shopList.map((shop, index) => (
			<TabPanel
				key={shop.shopId}
				value={index.toString()}
				className={classes.tabsPanel}>
				<Switch>
					<Route
						path='/new-order'
						render={() => <NewOrderTabs shopId={shop.shopId} />}
					/>
					<Route
						path='/menu'
						render={() => <MenuTabs shopId={shop.shopId} />}
					/>
					<Route
						path='/processing-order'
						render={() => <ProcessingOrderTabs shopId={shop.shopId} />}
					/>
					<Route
						path='/order-history'
						render={() => <OrderHistoryTabs shopId={shop.shopId} />}
					/>
				</Switch>
			</TabPanel>
		));
	};

	return (
		<Grid container>
			<TabContext value={shopIndex}>
				<Grid item xs={4}>
					<TabList
						className={classes.tabs}
						onChange={handleChange}
						textColor='primary'
						aria-label='shopList Tabs'
						orientation='vertical'>
						{getTabListItems()}
					</TabList>
				</Grid>
				<Grid item xs={8}>
					{getTabPanels()}
				</Grid>
			</TabContext>
		</Grid>
	);
};

export default ShopListTabs;
