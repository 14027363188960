import { makeStyles } from '@material-ui/styles';

// eslint-disable-next-line import/prefer-default-export
export const useStyles = makeStyles({
	messageTabs: {
		minHeight: '30px',
	},
	messageTab: {
		minHeight: '30px',
	},
	checkBox: {
		verticalAlign: 'top',
		borderBottomColor: 'rgb(61 61 61)',
		borderBottomWidth: '1px',
		borderBottomStyle: 'solid',
		paddingTop: '5px',
		'& .Mui-checked': {
			color: '#44CC00',
		},
	},
	orderType: {
		borderBottomColor: 'rgb(61 61 61)',
		borderBottomWidth: '1px',
		borderBottomStyle: 'solid',
		color: '#FFE700',
		padding: '5px',
		fontWeight: 500,
		fontSize: 13,
	},
	waitingButton: {
		borderRadius: '3px',
		color: '#A9A9A9',
		padding: '10px 30% 10px 30%',
		backgroundColor: 'rgb(61 61 61)',
	},
	inputQuotation: {
		color: 'white',
		'& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0,
		},
	},
	price: {
		fontSize: '16px',
	},
	serviceName: {
		fontSize: '20px',
		display: 'inline',
	},
	serviceDetails: {
		fontSize: '14px',
	},
	tableCell: {
		borderBottomColor: 'rgb(61 61 61)',
		borderBottomWidth: '1px',
		borderBottomStyle: 'solid',
	},
	button1: {
		'& .MuiButton-endIcon': {
			position: 'absolute',
			right: 16,
		},
		fontSize: '18px',
		fontWeight: 700,
		maxWidth: '90%',
		borderRadius: '25px',
	},
	button2: {
		maxWidth: '90%',
		borderRadius: '25px',
		fontSize: '18px',
	},
	button3: {
		'& .MuiButton-endIcon': {
			position: 'absolute',
			right: 16,
		},
		fontSize: '18px',
		fontWeight: 700,
		maxWidth: '90%',
		borderRadius: '25px',
	},
	button4: {
		maxWidth: '100%',
		borderRadius: '20px',
		paddingRight: '75px',
		paddingLeft: '75px',
	},
	acceptButton: {
		marginTop: '10px',
		textAlign: 'center',
	},
	acceptButton2: {
		marginTop: '10px',
		marginBottom: '10px',
		textAlign: 'center',
	},
	skipButton: {
		marginTop: '10px',
		textAlign: 'center',
	},
	info: {
		verticalAlign: 'middle',
		marginLeft: '0.5em',
	},
	drawerCell: {
		marginRight: '15%',
		marginLeft: '15%',
		fontWeight: 500,
	},
	drawer: {
		color: 'white',
		backgroundColor: '#222222',
		margin: '0',
	},
	totalPrice: {
		color: 'white',
		fontSize: '16px',
	},
	table: {
		color: 'white',
	},
	priceLabel: {
		color: 'white',
		fontSize: '16px',
		fontWeight: 300,
	},
	bookedDate: {
		backgroundColor: 'black',
		padding: '1vh',
		color: 'white',
	},
	preferDate: {
		display: 'inline-block',
		fontSize: '14px',
		fontWeight: 300,
	},
	car: {
		backgroundColor: '#FFE700',
		padding: '2vh',
		color: 'black',
	},
	carModel: {
		display: 'inline-block',
		color: '#222222',
		fontSize: '16px',
		fontWeight: 300,
		maxWidth: '300px',
	},
	date: {
		display: 'inline-block',
		float: 'right',
		fontSize: '14px',
		fontWeight: 300,
	},
	carLicensePlate: {
		fontSize: '16px',
		padding: '2.5%',
		display: 'inline-block',
		fontWeight: 700,
		float: 'right',
	},
	carOwner: {
		fontWeight: 700,
		paddingLeft: '9%',
	},
	textField: {
		maxWidth: '20px',
		display: 'inline-block',
		margin: '20px',
	},
	root: {},
	input: {
		color: 'white',
	},
	img: {
		maxWidth: '9%',
		height: 'auto',
		verticalAlign: 'middle',
		padding: '10px',
	},
	offerImg: {
		maxWidth: '9%',
		height: 'auto',
		verticalAlign: 'middle',
		padding: '4px',
		margin: '6px 0px 6px 0px',
	},
	yellow: {
		color: '#FFE700',
	},
	white: {
		color: 'rgba(255,255,255,0.90)',
	},
	container: {
		zIndex: 0,
		position: 'relative',
		paddingTop: '0vh',
		color: '#FFFFFF',
	},
	cardHidden: {
		opacity: '0',
		transform: 'translate3d(0, -60px, 0)',
	},
	pageHeader: {
		minHeight: '100vh',
		height: 'auto',
		display: 'inherit',
		position: 'relative',
		margin: '0',
		padding: '0',
		border: '0',
		alignItems: 'center',
		'&:before': {
			background: 'rgba(0, 0, 0, 0.5)',
		},
		'&:before,&:after': {
			position: 'absolute',
			zIndex: '1',
			width: '100%',
			height: '100%',
			display: 'block',
			left: '0',
			top: '0',
			content: '""',
		},
		'& footer li a,& footer li a:hover,& footer li a:active': {
			color: '#FFFFFF',
		},
		'& footer': {
			position: 'absolute',
			bottom: '0',
			width: '100%',
		},
	},
	form: {
		margin: '0',
	},
	cardHeader: {
		width: 'auto',
		textAlign: 'center',
		marginLeft: '20px',
		marginRight: '20px',
		marginTop: '-40px',
		padding: '20px 0',
		marginBottom: '15px',
	},
	socialIcons: {
		fontSize: '20px',
		height: '100%',
		left: '0',
		lineHeight: '41px',
		marginTop: '0',
		maxWidth: '24px',
		top: '0',
		transform: 'none',
		width: '100%',
	},
	divider: {
		marginTop: '30px',
		marginBottom: '0px',
		textAlign: 'center',
	},
	cardFooter: {
		paddingTop: '0rem',
		border: '0',
		borderRadius: '6px',
		justifyContent: 'center !important',
	},
	socialLine: {
		marginTop: '1rem',
		textAlign: 'center',
		padding: '0',
	},
	inputIconsColor: {
		color: '#495057',
	},
	datePicker: {
		'& .MuiInputBase-input': {
			color: 'white',
		},
		'& .MuiSvgIcon-root': {
			fill: 'white',
		},
	},
	iconLeft: { marginRight: '0.5em', width: '21px', height: '21px' },
});
