import { makeStyles } from '@material-ui/styles';
import homePageLogo from 'images/logo_automate_yellow.png';

const useStyles = makeStyles({
	title: {
		backgroundColor: '#222222',
		textAlign: 'center',
		padding: '11px',
	},
});

const HeaderPostLogin = () => {
	const classes = useStyles();

	return (
		<div className={classes.title}>
			<img
				src={homePageLogo}
				alt='Logo'
				style={{
					width: '320px',
					height: '30px',
				}}
			/>
		</div>
	);
};

export default HeaderPostLogin;
