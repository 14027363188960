import { CssBaseline } from '@material-ui/core';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import App from 'App';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import i18n from './i18n';
import theme from './theme/globalTheme';
import setAuthorizationHeader from './utils/setAuthorizationHeader';

// Set display language as that of user setting, if no setting default to English.
if (localStorage.language) i18n.changeLanguage(localStorage.language);
else localStorage.setItem('language', 'en');

// Set auth header for axios
if (localStorage.automateJWT) setAuthorizationHeader(localStorage.automateJWT);

ReactDOM.render(
	<ThemeProvider theme={theme}>
		<RecoilRoot>
			<BrowserRouter>
				<CssBaseline />
				<App />
			</BrowserRouter>
		</RecoilRoot>
	</ThemeProvider>,
	document.getElementById('root')
);
