import useServices from 'utils/services';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	TableCell,
	TableRow,
	Typography,
} from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { AddCircle, ExpandMore } from '@material-ui/icons';
import { useStyles } from 'theme/orderPage';
import { Dispatch, SetStateAction, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

interface AddServiceProps {
	setServices: Dispatch<SetStateAction<any>>;
}

const AddService = ({ setServices }: AddServiceProps) => {
	const { services } = useServices();
	const [Confirm, setConfirm] = useState({});
	const [Open, setOpen] = useState(false);
	const [expanded, setExpanded] = useState('');
	const [Selection, setSelection] = useState('');
	const classes = useStyles();
	const { t } = useTranslation(['Order']);

	const handleOpen = () => setOpen(!Open);

	const handleSubmit = () => {
		if (!_.isEmpty(Confirm)) {
			setServices((prevState: any) => [...prevState, Confirm]);
			setOpen(false);
		}
	};

	const handleChange =
		(panel: string) => (_event: object, isExpanded: boolean) =>
			setExpanded(isExpanded ? panel : '');

	const handleSelect = (event: any, newSelection: string) => {
		setConfirm({ name: event.target.innerText, id: newSelection, price: 0 });
		setSelection(newSelection);
	};

	const servicesCategory =
		services &&
		services.slice(0, -1).map((service: any, index: number) => (
			// slice off the last element: special service. It has no sub-services
			<Accordion
				key={service.name}
				expanded={expanded === `panel_${index}`}
				onChange={handleChange(`panel_${index}`)}>
				<AccordionSummary
					expandIcon={<ExpandMore />}
					aria-controls={`panel_${index}-content`}
					id={`panel_${index}-header`}>
					<Typography color='textSecondary'>{service.name}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					{service.includes?.length && (
						<ToggleButtonGroup
							exclusive
							aria-label='sub-services'
							orientation='vertical'
							style={{ width: '500px' }}
							value={Selection}
							onChange={handleSelect}>
							{service.includes.map((sub: any) => (
								<ToggleButton key={sub._id} value={sub._id}>
									{sub.name}
								</ToggleButton>
							))}
						</ToggleButtonGroup>
					)}
				</AccordionDetails>
			</Accordion>
		));

	return (
		<TableRow>
			<TableCell colSpan={3}>
				<Button
					fullWidth
					onClick={handleOpen}
					style={{ background: 'rgb(78, 78, 78)', color: 'white' }}>
					<AddCircle className={classes.iconLeft} />
					{t('Add Service')}
				</Button>
				<Dialog
					open={Open}
					color='primary'
					onClose={handleOpen}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'>
					<DialogContent>{servicesCategory}</DialogContent>
					<DialogActions>
						<Button color='primary' variant='contained' onClick={handleOpen}>
							{t('Cancel')}
						</Button>
						<Button color='primary' variant='contained' onClick={handleSubmit}>
							{t('Confirm')}
						</Button>
					</DialogActions>
				</Dialog>
			</TableCell>
		</TableRow>
	);
};

export default AddService;
