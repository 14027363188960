import { atom } from 'recoil';

enum Recoil {
	LOGIN = 'login',
	SHOP_INDEX = 'shopIndex',
}

const RecoilStates = {
	login: atom({
		key: Recoil.LOGIN,
		default: !!localStorage.automateJWT,
	}),

	shopIndex: atom({
		key: Recoil.SHOP_INDEX,
		default: localStorage.getItem('shopIndex') ?? '0',
	}),
};

export default RecoilStates;
