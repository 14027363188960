// @material-ui/core components
import { Box } from '@material-ui/core';
import LoginForm from '../forms/LoginForm';

const LoginPage = () => (
	<>
		<Box mt='200px'>
			<LoginForm />
		</Box>
	</>
);
export default LoginPage;
