import { Grid } from '@material-ui/core';
import SideNavigation from '../navigation/SideNavigation';
import CustomBottomNavigation from '../navigation/BottomNavigation';
import WaitingPanel from './panels/WaitingConfirmationPanel';

const WaitingConfirmationPage = () => (
	<>
		<Grid container justifyContent='center'>
			<Grid item xs={4} md={2}>
				<SideNavigation pathname='/new-order' />
			</Grid>
			<Grid item xs={8} md={4}>
				<WaitingPanel />
			</Grid>
		</Grid>
		<CustomBottomNavigation value='0' />
	</>
);

export default WaitingConfirmationPage;
