import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@material-ui/core';
import { Dispatch, SetStateAction, useState } from 'react';

export interface CustomDialogProps {
	title?: string;
	text?: string;
	button?: string;
	dispatch: Dispatch<SetStateAction<boolean>>;
}

const CustomDialog = ({
	title = '價錢無效',
	text = '請輸入有效價錢',
	button = '好的',
	dispatch,
}: CustomDialogProps) => {
	const [Open, setOpen] = useState(true);
	const handleOpen = () => {
		setOpen(!Open);
		dispatch(false);
	};

	return (
		<Dialog
			open={Open}
			color='primary'
			onClose={handleOpen}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'>
			<DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText id='alert-dialog-description'>
					{text}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleOpen} autoFocus>
					{button}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CustomDialog;
