import api, { Credentials } from '../api';
import setAuthorizationHeader from './setAuthorizationHeader';

export const login = (credentials: Credentials) =>
	api.user.login(credentials).then((data) => {
		localStorage.automateJWT = data.access_token;
		localStorage.refreshToken = data.refresh_token;
		localStorage.mechanicId = data.data.mechanic._id;
		localStorage.language = data.data.mechanic.settings.lang;
		localStorage.setItem('username', data.data.mechanic.name);
		setAuthorizationHeader(data.access_token);
	});

export const logout = () => {
	localStorage.removeItem('automateJWT');
	localStorage.removeItem('mechanicId');

	setAuthorizationHeader();
};

// export const validateToken = (token) => () => api.user.validateToken(token);
// export const resetPassword = (data) => () => api.user.resetPassword(data);
