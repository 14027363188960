import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { discardQuote } from 'utils/orders';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import CustomDialog from './CustomDialog';

export interface DiscardProps {
	redirectLink: string;
	orderId: string;
}

const DiscardButton = ({ redirectLink, orderId }: DiscardProps) => {
	const [Open, setOpen] = useState(false);
	const [DiscardError, setDiscardError] = useState(false);
	const history = useHistory();
	const { t } = useTranslation('Order');

	const handleSkip = () => {
		setOpen(!Open);
		discardQuote(orderId)
			.then(() => {
				const state = {
					jobTitle: 'Quotation is discarded',
					jobDetails: 'This order has been discarded',
					nextPath: redirectLink,
				};
				history.replace('/job-done', state);
			})
			.catch(() => setDiscardError(true));
	};

	return (
		<>
			<Button
				onClick={() => setOpen(!Open)}
				size='large'
				fullWidth
				style={{ color: 'red', marginTop: '2em' }}>
				{t('Discard this job')}
			</Button>
			{DiscardError && (
				<CustomDialog
					title='Error'
					text='An error has occured'
					dispatch={setDiscardError}
				/>
			)}
			<Dialog
				open={Open}
				color='primary'
				onClose={() => setOpen(false)}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						{t('Do you want to skip this Order?')}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpen(!Open)} autoFocus>
						{t('No')}
					</Button>
					<Button onClick={handleSkip}>{t('Yes')}</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default DiscardButton;
