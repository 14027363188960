import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { Link } from 'react-router-dom';
import { AddToPhotos, EventAvailable, Menu } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
	nav: {
		backgroundColor: '#000000',
		position: 'fixed',
		bottom: 0,
		width: '100%',
	},
	navButton: {
		'&:hover': {
			color: 'rgb(78, 78, 78)',
		},
		color: 'rgba(255,255,255,0.90)',
	},
});

export interface BottomNavProps {
	value: string;
}

const BottomNav = ({ value }: BottomNavProps) => {
	const classes = useStyles();
	const { t } = useTranslation('BottomNav');
	return (
		<BottomNavigation value={value} showLabels className={classes.nav}>
			<BottomNavigationAction
				component={Link}
				to='/new-order'
				className={classes.navButton}
				value='0'
				label={t('new')}
				icon={<AddToPhotos />}
			/>
			<BottomNavigationAction
				component={Link}
				to='/processing-order'
				className={classes.navButton}
				value='1'
				label={t('jobs')}
				icon={<EventAvailable />}
			/>
			<BottomNavigationAction
				component={Link}
				to='/menu'
				className={classes.navButton}
				value='2'
				label={t('more')}
				icon={<Menu />}
			/>
		</BottomNavigation>
	);
};

export default BottomNav;
